<template>
  <v-card dark>
    <v-card-title>
      Jeux hebdo
      <v-divider/>
      <game-contest-form/>
      <game-contest-winner-selection-dialog/>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :server-items-length="totalItems"
        sort-by="id"
        sort-desc
        class="elevation-15"
        :page="page"
        :items-per-page="itemsPerPage"
        @update:page="updatePage"
        @update:items-per-page="updateItemsPerPage"
    >
      <!-- template des headers !-->
      <template v-slot:top>
      </template>
      <!-- template des éléments !-->
      <template v-slot:item.description="{ item }">
        <div v-html="item.description"/>
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ getDate(item.startDate) }}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{ getDate(item.endDate) }}
      </template>
      <template v-slot:item.gameContestType="{item}">
        {{ getGameContestLabel(item.gameContestType) }}
      </template>
      <template v-slot:item.nbParticipations="{item}">
        {{ item.nbParticipations }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)" color="red">mdi-delete</v-icon>
        <v-btn v-if="!item.isOver" @click="gameContestWinnerSelected = item">Participations</v-btn>
      </template>
      <template v-slot:no-data>
        Aucun jeu concours trouvé.
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import GameContestMixin from "../../../mixins/content/GameContestMixin";
import moment from "moment";
import GameContestForm from "./GameContestForm";
import GameContestWinnerSelectionDialog from "./GameContestWinnerSelectionDialog";

export default {
  name: "GamesContestDataTable",
  components: {GameContestWinnerSelectionDialog, GameContestForm},
  mixins: [GameContestMixin],
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'id',
          align: 'start',
          filterable: false,
          value: 'id',
        },
        {text: 'Titre', value: 'title', sortable: true},
        {text: 'Description', value: 'description'},
        {text: 'Commence le', value: 'startDate'},
        {text: 'Termine le', value: 'endDate'},
        {text: 'Type de jeu concours', value: 'gameContestType', sortable: true},
        {text: 'Nombre de participants', value: 'nbParticipations', sortable: true},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    getDate(dateFromDb) {
      return dateFromDb ? moment(dateFromDb).format("D-M-Y") : "NC";
    },
    editItem(item) {
      this.dialog = true;
      this.$nextTick(() => {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.$nextTick(() => {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
  },
}
</script>

<style scoped>

</style>