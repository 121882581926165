import {getField, updateField} from 'vuex-map-fields';
import ShopService from "../../../services/shop.service";

export const productCategory = {
    namespaced: true,
    state: {
        productCategories: [],
        totalProductCategories: 0,
        loading: false,
        dialog: false,
        dialogDelete: false,
        editedItem: {
            name: "",
            description: "",
            isAwardPointsProduct :false

        },
        editedIndex: -1,
        defaultItem: {
            name: "",
            description: "",
            isAwardPointsProduct :false
        },
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_PRODUCT_CATEGORIES(state, payload) {
            state.productCategories = payload.productCategories;
            state.totalProductCategories = payload.totalProductCategories;
        },
        UPDATE_PRODUCT_CATEGORY(state, payload) {
            Object.assign(state.productCategories[payload.index], payload.productCategory);
        },
        ADD_PRODUCT_CATEGORY(state, payload) {
            state.productCategories.push(payload.productCategory);
            state.editedItem = payload.productCategory;
            state.editedIndex = state.productCategories.length - 1;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
    },
    actions: {
        async getProductCategories({commit, dispatch}, payload = {}) {
            return new Promise((resolve, reject) => {
                let pagination = payload.pagination ? payload.pagination:{};
                commit('SET_LOADING', true);
                ShopService.getProductCategories(pagination).then(response => {
                    let payload = {
                        productCategories: response.data['hydra:member'],
                        totalProductCategories: response.data['hydra:totalItems']
                    }
                    commit('SET_PRODUCT_CATEGORIES', payload)
                    return resolve();
                }).catch(error => {
                    console.error(error);
                    dispatch("message/showErrorMessage", "Erreur de chargement des catégories de produit", {root: true})
                    reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async updateProductCategory({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let index = state.editedIndex;
                let productCategory = {...state.editedItem};
                ShopService.updateProductCategory(productCategory).then(() => {
                    commit('UPDATE_PRODUCT_CATEGORY', {index: index, productCategory: productCategory});
                    dispatch("message/showSuccessMessage", "Catégorie de produit mise à jour avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de modification de la catégorie de produit", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            });
        },
        async deleteProductCategory({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let productCategory = {...state.editedItem};
                ShopService.deleteProductCategory(productCategory).then(() => {
                    dispatch("message/showSuccessMessage", "Catégorie de produit  effacée avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de modification de la catégorie", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });

            })
        },
        async createProductCategory({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let productCategory = {...state.editedItem};
                ShopService.createProductCategory(productCategory).then((response) => {
                    commit('ADD_PRODUCT_CATEGORY', {productCategory: response.data});
                    dispatch("message/showSuccessMessage", "Catégorie de produit ajoutée avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur d'ajout de la catégorie de produit", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
    }
}