<template>
  <v-container fluid>
    <v-row>
      <v-spacer/>
      <v-col cols="6" lg="3" class="text-right">
      </v-col>
    </v-row>
    <payments-data-table/>
  </v-container>
</template>

<script>
import PaymentsDataTable from "../../components/sales/payment/PaymentsDataTable";
export default {
  name: "Payments",
  components: {PaymentsDataTable}
}
</script>

<style scoped>

</style>