<template>
  <v-card dark>
    <v-card-title>
      Joueurs
      <v-divider/>
      <player-form/>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :server-items-length="totalItems"
        sort-by="id"
        sort-desc
        class="elevation-15"
        :page="page"
        :items-per-page="itemsPerPage"
        @update:page="updatePage"
        @update:items-per-page="updateItemsPerPage"
    >
      <!-- template des headers !-->
      <template v-slot:top>
      </template>
      <!-- template des éléments !-->
      <template v-slot:item.teams="{ item }">
        <pg-icon s-m inline v-if="item.teams.length" class="ma-auto">i-pimentos</pg-icon>
      </template>
      <template v-slot:item.total="{ item }">
        <span v-html="item.total"></span> €
      </template>
      <template v-slot:item.awardPoints="{ item }">
        <span>{{ item.awardPoints }} <pg-icon s-m inline>i-pimentos</pg-icon></span>
      </template>
      <template v-slot:item.gamePlayedByPlayerCounters="{ item }">
        <span>{{ countTotalGames(item.gamePlayedByPlayerCounters) }}</span>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ getDate(item.createdAt) }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ getDate(item.updatedAt) }}
      </template>
      <template v-slot:item.verifiedAt="{ item }">
        {{ getDate(item.verifiedAt) }}
      </template>
      <!-- template des boutons de gestion crud !-->
      <!--      <template v-slot:item.actions="{ item }">-->
      <!--        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>-->
      <!--        <v-icon small class="mr-2" @click="deleteItem(item)" color="red">mdi-delete</v-icon>-->
      <!--      </template>-->
      <!-- template pas de données!-->
      <template v-slot:no-data>
        Aucun joueur trouvé.
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";
import PlayerMixin from "../../../mixins/players/PlayerMixin";
import PlayerForm from "./PlayerForm";
import PgIcon from "../../PGIcon";

export default {
  name: "PlayersDataTable",
  mixins: [PlayerMixin],
  components: {PgIcon, PlayerForm},
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'id',
          align: 'start',
          filterable: false,
          value: 'id',
        },
        {text: 'Mail', value: 'email', sortable: true},
        {text: 'Pseudo', value: 'nickName'},
        {text: 'Nom', value: 'LastName'},
        {text: 'Prénom', value: 'firstName'},
        {text: 'Couple', value: 'teams'},
        {text: 'Créé le', value: 'createdAt'},
        {text: 'Mis à jour le', value: 'updatedAt'},
        {text: 'Vérifié le', value: 'verifiedAt'},
        {text: 'Pimentos', value: 'awardPoints', sortable: false},
        {text: 'Parties jouées', value: 'gamePlayedByPlayerCounters', sortable: true},
        {text: 'Token mail', value: 'emailVerificationToken', sortable: true},
        {text: 'Token password', value :'passwordResetToken', sortable: true},
        // {text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    getDate(dateFromDb) {
      return dateFromDb ? moment(dateFromDb).format("D-M-Y") : "NC";
    },
    editItem(item) {
      this.dialog = true;
      this.$nextTick(() => {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.$nextTick(() => {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
    countTotalGames(gamePlayedByPlayerCounters) {
      let sum = 0;
      gamePlayedByPlayerCounters.forEach(counter => {
        sum += counter.countPlayed;
      })
      return sum;
    }
  },
  created() {
    this.initialize();
  },
  watch: {}
}
</script>

<style scoped>

</style>