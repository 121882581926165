import {createHelpers} from 'vuex-map-fields';

const {mapFields} = createHelpers({
    getterType: 'notification/getField',
    mutationType: 'notification/updateField'
});

export default {
    computed: {
        ...mapFields(['notifications', 'showNotifications']),
        nbNotificationToRead() {
            return this.notifications.filter(item => item.notRead).length;
        }
    },
    created() {

    },
}