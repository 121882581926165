import {getField, updateField} from 'vuex-map-fields';
import GameService from "../../../services/game.service";

export const game = {
    namespaced: true,
    state: {
        games: [],
        totalGames: 0,
        selectedGameType: null,
        gameTypes: [],
        gameFilters: [],
        selectedFilters: [],
        loading: false,
        dialog: false,
        dialogDelete: false,
        dialogPublisher: false,
        dialogGameStageEditor: false,
        isCommunity: false,
        filterIsValidatedCommunity: false,
        filterIsRefusedCommunity: false,
        filterIsWaiting: false,
        editedItem: {
            descriptionLong: "",
            descriptionShort: "",
            pointsReward: 15,
            pointsCost: 0,
            gameType: null,
            categoryElements: [],
            image: null,
            publicationStart: null,
            publicationEnd: null,
            nbPlayersMin: 2,
            nbPlayersMax: 2,
            duration:"7200"
        },
        editedIndex: -1,
        defaultItem: {
            title: "",
            descriptionLong: "",
            descriptionShort: "",
            pointsReward: 15,
            pointsCost: 0,
            gameType: null,
            categoryElements: [],
            image: null,
            publicationStart: null,
            publicationEnd: null,
            nbPlayersMin: 2,
            nbPlayersMax: 2,
            duration:"7200"
        },
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false


    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_TYPES(state, payload) {
            state.gameTypes = payload;
            if (state.gameTypes.length && state.selectedGameType === null) {
                state.selectedGameType = state.gameTypes[0].code;
            }
        },
        SET_GAMES(state, payload) {
            state.games = payload.games;
            state.totalGames = payload.totalGames;
            console.log(payload)
        },
        UPDATE_GAME(state, payload) {
            Object.assign(state.games[payload.index], payload.game);
        },
        ADD_GAME(state, payload) {
            state.games.push(payload.game);
            state.editedItem = payload.game;
            state.editedIndex = state.games.length - 1;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_FILTERS(state, payload) {
            state.gameFilters = payload;
        },
        SET_GAME_PICTURE(state, payload) {
            if (state.editedItem.image) {
                Object.assign(state.editedItem.image, payload);
            } else {
                state.editedItem.image = payload;
            }
        },

    },
    actions: {
        async getGameTypes({commit}) {
            return new Promise((resolve, reject) => {
                GameService.getGameTypes().then(response => {
                    commit('SET_TYPES', response.data['hydra:member']);
                    return resolve();
                }).catch(error => {
                    return reject(error);
                });
            })
        },
        async getGamesByType({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let gameTypeCode = payload.gameTypeCode, gameFilters = payload.gameFilters ? payload.gameFilters : [];
                let isCommunity = payload.isCommunity != undefined ? payload.isCommunity : false;
                let filterCommunity = payload.filterCommunity;
                let pagination = payload.pagination;
                commit('SET_LOADING', true);
                GameService.getGames(gameTypeCode, gameFilters, isCommunity, filterCommunity, pagination).then(response => {
                    let payload = {
                        games: response.data['hydra:member'],
                        totalGames: response.data['hydra:totalItems']
                    }
                    commit('SET_GAMES', payload)
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de chargement des jeux", {root: true})
                    reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async updateGame({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let index = state.editedIndex;
                let game = {...state.editedItem};
                GameService.updateGame(game).then(() => {
                    commit('UPDATE_GAME', {index: index, game: game});
                    dispatch("message/showSuccessMessage", "Jeu mis à jour avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de modification du jeu", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            });
        },
        async deleteGame({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let game = {...state.editedItem};
                GameService.deleteGame(game).then(() => {
                    dispatch("message/showSuccessMessage", "Jeu effacé avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de suppression du jeu", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });

            })
        },
        async createGame({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let game = {...state.editedItem};
                GameService.createGame(game).then((response) => {
                    commit('ADD_GAME', {game: response.data});
                    dispatch("message/showSuccessMessage", "Jeu ajouté avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur d'ajout du jeu", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async getGameFilters({commit}) {
            return new Promise((resolve, reject) => {
                GameService.getGameFilters().then(response => {
                    commit('SET_FILTERS', response.data['hydra:member']);
                    return resolve();
                }).catch(error => {
                    return reject(error);
                });
            })
        }
        ,
        async uploadGamePicture({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
                GameService.uploadGamePicture(payload.fd, payload['@id']).then((response) => {
                    dispatch("message/showSuccessMessage", "Image envoyé avec succès!", {root: true})
                    commit('SET_GAME_PICTURE', response.data);
                    return resolve(response.data);
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur d'envoie de l'image", {root: true})
                    return reject(error);
                })
            })
        }
    }
}