<template>
  <v-select label="Genre" clearable :items="avatarGenderTypes" item-text="code" v-model="selected" item-value="@id"></v-select>
</template>

<script>
import AvatarMixin from "../../../mixins/AvatarMixin";

export default {
  name: "AvatarGenderTypeSelect",
  mixins: [AvatarMixin],
  props: ['value'],
  data() {
    return {
      selected: "",
    }
  },
  watch: {
    selected: function () {
      this.$emit('input', this.selected);
    },
    value: function () {
      this.selected = this.value;
    }
  }
}
</script>

<style scoped>

</style>