<template>
  <v-bottom-navigation absolute class="pg-color-white" v-model="value" height="65px" dark >
    <v-spacer/>
    <span class="font-weight-light copyright">
      &copy;
      {{ (new Date()).getFullYear() }}
     , fait avec
      <v-icon
          color="red"
          size="17">mdi-heart</v-icon>
      par <a href="https://jinic-media.com"
             target="_blank">Jinic-Média</a>
    </span>
  </v-bottom-navigation>
</template>

<script>

export default {
  name: "FooterMenu",
  data: () => ({value: 'play'}),
}
</script>

<style scoped>

</style>