import Orders from "../views/sales/Orders";
import Payments from "../views/sales/Payments";

export default {
    path: '/sales',
    name: 'Sales',
    component: () => import('../layouts/Base'),
    children: [
        {
            path: "/sales/orders",
            name: "Orders",
            component: Orders,
        },
        {
            path: "/sales/payments",
            name: "Payments",
            component: Payments,
        },
    ]
};
