<template>
  <v-card>
    <v-row no-gutters>
      <v-img
          :src='getImagePath'
          aspect-ratio="1.7778"
          contain
          width="100%"
          v-show="getImagePath!=''"
          :class="light?'white':'black'"
          :gradient="gradiant"
      />
    </v-row>
    <input type="file" @change="processFile($event)" style="display: none" ref="pictureInput">
    <v-card-actions>
      <v-btn @click="$refs.pictureInput.click()">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn @click="onSave" :disabled="!tempImgUrl">
        <v-icon>mdi-cloud-upload</v-icon>
      </v-btn>
      <v-divider/>
      <v-switch label="light" v-model="light"></v-switch>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ImageUploader",
  props: ['value', 'uploadFunction', 'imageOwner'],
  data: () => {
    return {
      tempImgUrl: "",
      image: {
        "@id": null,
        "contentUrl": "",
      },
      imageData: null,
      light:false,
    }
  },
  computed: {
    getImagePath() {
      if (this.tempImgUrl != "") {
        return this.tempImgUrl
      } else {
        if (this.image && this.image.contentUrl != "") {
          return process.env.VUE_APP_API_URL + this.image.contentUrl
        }
      }
      return ''
    },
    gradiant() {
      if (this.tempImgUrl != "") {
        return "to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
      } else {
        return ""
      }
    }
  },
  methods: {
    processFile(event) {
      this.imageData = event.target.files[0]
      this.tempImgUrl = URL.createObjectURL(this.imageData)
    },
    onSave() {
      const fd = new FormData();
      if (this.imageData) {
        fd.append('file', this.imageData, this.imageData.name)
        if (this.imageOwner) {
          fd.append(this.imageOwner.type, this.imageOwner.id);
        }
        let payload = {
          fd: fd,
          idResource: this.imageOwner.id,
          type: this.imageOwner.type,
          '@id': this.image ? this.image['@id'] : null
        }
        this.uploadFunction(payload).then((response) => {
          this.imageData = null;
          this.tempImgUrl = "";
          this.image = response;
          this.$emit("update:image", true);
        });
      }
    }
  },
  created() {
    this.image = this.value;
  },
  watch: {
    image: function () {
      this.$emit('input', this.image);
    },
    value: function () {
      this.image = this.value;
    }

  }
}
</script>

<style scoped>

</style>