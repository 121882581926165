import PlayersManager from "../views/players/PlayersManager";


export default {
    path: '/players',
    name: 'Players',
    component: () => import('../layouts/Base'),
    children: [
        {
            path: "/players/manager",
            name: "PlayersManager",
            component: PlayersManager,
        }
    ]
};
