var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"dark":""}},[_c('v-card-title',[_vm._v(" Joueurs "),_c('v-divider'),_c('player-form')],1),_c('v-data-table',{staticClass:"elevation-15",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"server-items-length":_vm.totalItems,"sort-by":"id","sort-desc":"","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.teams",fn:function(ref){
var item = ref.item;
return [(item.teams.length)?_c('pg-icon',{staticClass:"ma-auto",attrs:{"s-m":"","inline":""}},[_vm._v("i-pimentos")]):_vm._e()]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.total)}}),_vm._v(" € ")]}},{key:"item.awardPoints",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.awardPoints)+" "),_c('pg-icon',{attrs:{"s-m":"","inline":""}},[_vm._v("i-pimentos")])],1)]}},{key:"item.gamePlayedByPlayerCounters",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.countTotalGames(item.gamePlayedByPlayerCounters)))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.updatedAt))+" ")]}},{key:"item.verifiedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.verifiedAt))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" Aucun joueur trouvé. ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }