import APIService from "./api.service";

class GameService {
    async getGameFilters() {
        return APIService.get('/game_categories');
    }

    async getGameTypes() {
        return APIService.get('/game_types?isListed=true');
    }

    async getGameStagePlayerInteractionTypes() {
        return APIService.get('/game_stage_player_interaction_types');
    }

    async getGames(gameTypeCode, gameFiters = [], isCommunity = false, filterCommunity = {}, pagination = {}) {
        let url = '/games?';
        if (gameTypeCode) {
            url += 'gameType.code=' + gameTypeCode;
        }
        gameFiters.forEach(filtersArray => {
            filtersArray.forEach(filterId => {
                url += '&categoryElements.id=' + filterId
            })
        })
        isCommunity ? url += '&exists[author]=true&isPrivate=false' : url += '&exists[author]=false';
        if (isCommunity) {
            console.log(filterCommunity)
            if (filterCommunity.isWaiting) {
                url += '&exists[publicationStart]=false';
                url += '&exists[validatedBy]=false';
                url += '&exists[refusedBy]=false';
            }
            if (filterCommunity.isValidated) {
                url += '&exists[validatedBy]=true'
            }
            if (filterCommunity.isRefused) {
                url += '&exists[refusedBy]=true'
            }
        }
        console.log(pagination)
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 10;
        url += '&page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage
        }
        return await APIService.get(url);
    }

    async updateGame(game) {
        return await APIService.put(game['@id'], game);
    }

    async createGame(game) {
        return await APIService.post('/games', game);
    }

    async deleteGame(game) {
        return await APIService.delete(game['@id']);
    }

    async uploadGamePicture(data, gameId = null) {
        let url = '/game_images';
        if (gameId) {
            url = gameId
        }
        return await APIService.post(url, data);
    }

    async getStages(game) {
        return await APIService.get(game['@id']+ '/game_stages?itemsPerPage=100');
    }

    async createStage(stage) {
        return await APIService.post('/game_stages', stage);
    }

    async deleteStage(stage) {
        return await APIService.delete(stage['@id']);
    }

    async updateStage(stage) {
        return await APIService.put(stage['@id'], stage)
    }
}

export default new GameService();