<template>
  <v-container fluid>
    <v-row no-gutters v-if="loading" align="center" justify="center" class="fill-height">
      <v-progress-circular
          :size="300"
          :width="7"
          color="purple"
          indeterminate
          class="ma-auto"
      ></v-progress-circular>
    </v-row>
    <div v-else>
      <avatar-element-form/>
      <v-row>
        <v-spacer/>
        <v-col cols="6" lg="3" class="text-right">
        </v-col>
      </v-row>
      <avatar-builder/>
      <avatar-element-categories/>
      <v-row>
        <v-col cols="6">
          <avatar-hair-colors/>
        </v-col>
        <v-col cols="6">
          <avatar-skin-colors/>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

import AvatarElementCategories from "../../components/content/avatars/AvatarElementCategories";
import {mapActions} from "vuex";
import AvatarElementForm from "../../components/content/avatars/AvatarElementForm";
import AvatarBuilder from "../../components/content/avatars/AvatarBuilder";
import AvatarMixin from "../../mixins/AvatarMixin";
import AvatarHairColors from "../../components/content/avatars/AvatarHairColors";
import AvatarSkinColors from "../../components/content/avatars/AvatarSkinColors";

export default {
  name: "Avatars",
  components: {
    AvatarSkinColors,
    AvatarHairColors, AvatarBuilder, AvatarElementForm, AvatarElementCategories
  },
  mixins: [AvatarMixin],
  methods: {
    ...mapActions({
      getAvatarElementCategories: "avatar/getAvatarElementCategories",
      getAvatarGenderTypes: "avatar/getAvatarGenderTypes",
      getAvatarHairColors: "avatar/getAvatarHairColors",
      getAvatarSkinColors: "avatar/getAvatarSkinColors",
      getAvatarShapeTypes: "avatar/getAvatarShapeTypes"
    })
  },
  created() {
    this.getAvatarElementCategories();
    this.getAvatarHairColors();
    this.getAvatarSkinColors();
    this.getAvatarShapeTypes();
    this.getAvatarGenderTypes();

  }
}
</script>

<style scoped>

</style>