import {createHelpers} from "vuex-map-fields";
import {EventSourcePolyfill} from 'event-source-polyfill';

const {mapFields} = createHelpers({
    getterType: 'mercure/getField',
    mutationType: 'mercure/updateField'
});

export default {
    computed: {
        ...mapFields({
            mercureStarted: 'started',
            jwtToken: 'jwtToken',
            lastMessage: 'lastMessage',
            lastEventId: 'lastEventId'
        }),
    },
    data: function () {
        return {
            updateEventSource: null
        }
    },
    methods: {

        setupMercure(topics, callBack) {
            const url = new URL(process.env.VUE_APP_MERCURE_URL);
            topics.forEach(topic => {
                url.searchParams.append('topic', topic);
            });
            this.updateEventSource && this.updateEventSource.close();
            let headers = {
                Authorization: `Bearer ` + this.jwtToken,
            };
            this.updateEventSource = new EventSourcePolyfill(url, {
                headers: headers,
            });
            this.updateEventSource.onmessage = e => {
                console.log("Mercure message :");
                this.lastMessage = e.data;
                this.lastEventId = e.lastEventId;
                callBack(e);
            }
            this.updateEventSource.onopen = () => {
                console.log("Mercure ready to listen");
                this.mercureStarted = true;
            };
            this.updateEventSource.onerror = () => {
                this.mercureStarted = false;
            }
        },
        setupMercureFake() {
            console.log('Setting up event source fake');
            let topics = ['https://api.private-games.com/fake'];
            this.setupMercure(topics, (event) => {
                let notification = JSON.parse(event.data);
                console.log(notification);
                this.addLiveNotification(notification);
            });
        }
    },
    destroyed() {
        this.mercureStarted = false;
        if (this.updateEventSource) {
            this.updateEventSource.close();
        }
    }
}