<template>
  <v-card v-if="index != undefined" light>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <image-uploader v-model="avatarElementCategory.img" :upload-function="uploadAvatarPicture"
                          :image-owner="{type:'avatar_element_categories','id':avatarElementCategory['@id']}"
          />
          <!--                         v-on:update:image="saveGame(false)"/>-->
        </v-col>
        <v-col cols="4">
          <v-text-field
              label="Nom de la catégorie d'élément d'avatar"
              hide-details="auto"
              v-model="avatarElementCategory.title"
              :readonly="!edit"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-row no-gutters>
            <v-checkbox
                v-model="avatarElementCategory.isSkinRelated"
                label="est lié à la couleur de peau"
                :readonly="!edit"
            ></v-checkbox>
            <v-checkbox
                v-model="avatarElementCategory.isClothing"
                label="est un vêtement/accessoire"
                :readonly="!edit"
            ></v-checkbox>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-slider
              v-model="avatarElementCategory.zIndex"
              :thumb-size="24"
              thumb-label="always"
              :max="500"
              label="Position de calque"
              :readonly="!edit"
          ></v-slider>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="text-right">
      <v-switch v-model="edit" label="Édition"/>
      <v-divider></v-divider>
      <v-btn :disabled="!edit" @click="updateAvatarElementCategory(avatarElementCategory)">Sauver</v-btn>
      <v-btn color="red" dark @click="deleteAvatarElementCategory(avatarElementCategory)">Effacer
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-row>
        <avatar-elements :index="index"/>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ImageUploader from "../../ImageUploader";
import {mapActions} from "vuex";
import AvatarMixin from "../../../mixins/AvatarMixin";
import AvatarElements from "./AvatarElements";


export default {
  name: "AvatarElementCategory",
  data() {
    return {
      edit: false,
    }
  },
  components: {AvatarElements, ImageUploader},
  mixins: [AvatarMixin],
  props: ['index'],
  computed: {
    avatarElementCategory: function () {
      return this.avatarElementCategories[this.index];
    }
  },
  methods: {
    ...mapActions({
      uploadAvatarPicture: "avatar/uploadAvatarPicture",
      updateAvatarElementCategory: "avatar/updateAvatarElementCategory",
      deleteAvatarElementCategory: 'avatar/deleteAvatarElementCategory'
    })
  }
}
</script>

<style scoped>

</style>