<template>
  <v-container>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="2" align-self="start" class="text-center">
        <v-avatar height="200" width="200" class="avatar">
          <img width="200" height="200"
               :src="elementSelected.element?getImagePath(elementSelected.element.img.contentUrl):''"
               :style="'z-index:'+elementSelected.zIndex"
               v-for="(elementSelected,index) in selectedAvatarElements" :key="index"
               v-show="elementSelected.element"/>
        </v-avatar>
        <v-btn class="ma-auto" @click="generateRandom(true)">Generate</v-btn>
      </v-col>
      <v-col cols="10" align-self="center">
        <v-row no-gutters>
          <v-col cols="3">
            <avatar-gender-type-select v-model="genderTypeSelected"/>
          </v-col>
          <v-col cols="3">
            <avatar-skin-color-select v-model="skinColorSelected"/>
          </v-col>
          <v-col cols="3">
            <avatar-hair-color-select v-model="hairColorSelected"/>
          </v-col>
          <v-col cols="3">
            <avatar-shape-type-select v-model="shapeTypeSelected"/>
          </v-col>
        </v-row>
        <v-row no-gutters class="purple">
          <v-col cols="1" v-for="(avatarElementCategory,index) in avatarElementCategories" :key="index" class="pa-2">
            <v-avatar rounded class="elevation-10" width="64px" height="64px">
              <v-img contain width="64px" max-width="64px" :src="getImagePath(avatarElementCategory.img.contentUrl)"
                     class="white clickable ma-auto pa-0 ma-0"
                     v-if="avatarElementCategory.img && avatarElementCategory.img.contentUrl"
                     @click="selectCategory(avatarElementCategory)">
              </v-img>
            </v-avatar>
          </v-col>
        </v-row>
        <v-row no-gutters class="pa-2 pink" style="min-height: 200px">
          <v-col cols="1" class="pa-2" v-if="selectedCategory && !selectedCategory.isSkinRelated">
            <v-avatar rounded class="elevation-10" width="96px" height="96px">
              <v-img contain width="96px" max-width="96px"
                     src="@/assets/cancellation-avatar.png"
                     class="white clickable elevation-10"
                     @click="removeSelectedElementForCategory"/>
            </v-avatar>
          </v-col>

          <v-col cols="1" class="pa-2" v-show="isShown(avatarElement)" v-for="(avatarElement,i) in avatarParentElements"
                 :key="i">
            <v-card v-if="avatarElement.img">
              <v-avatar rounded class="elevation-10" width="96px" height="96px"
                        @click="$set(showedChildElements,i,!showedChildElements[i])"
              >
                <v-img contain width="96px" max-width="96px" :src="getImagePath(avatarElement.img.contentUrl)"
                       class="white pa-0 ma-0 clickable parent">
                </v-img>
              </v-avatar>
            </v-card>
            <v-dialog v-model="showedChildElements[i]" max-width="130px" scrollable v-if="showedChildElements[i]">
              <v-row no-gutters>
                <v-col v-for="(avatarElementChild,j) in avatarElement.childAvatarElements" :key="j" cols="12"
                       class="pa-2" v-show="isShown(avatarElementChild)">
                  <v-avatar rounded class="elevation-10" width="96px" height="96px" v-if="isShown(avatarElementChild)">
                    <v-img width="96px" max-width="96px"
                           :src="getImagePath(avatarElementChild.img.contentUrl)"
                           v-if="avatarElementChild.img && avatarElementChild.img.contentUrl"
                           class="white zoom clickable"
                           @click="selectElement(avatarElementChild)">
                      <aside class=ribbon-banner :style="getRubbanColor(avatarElementChild)"></aside>
                    </v-img>
                    {{avatarElement['@id']}}
                  </v-avatar>
                </v-col>
              </v-row>
            </v-dialog>
          </v-col>


          <v-col cols="1" v-for="(avatarElement,index) in avatarElements" :key="index" class="pa-2"
                 v-show="isShown(avatarElement)">
            <v-avatar rounded class="elevation-10" width="96px" height="96px">
              <v-img contain width="96px" max-width="96px" :src="getImagePath(avatarElement.img.contentUrl)"
                     class="white clickable elevation-10 zoom" v-if="avatarElement.img && avatarElement.img.contentUrl"
                     @click="selectElement(avatarElement)">
                <aside class=ribbon-banner :style="getRubbanColor(avatarElement)"></aside>
              </v-img>
              {{avatarElement['@id']}}
            </v-avatar>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AvatarMixin from "../../../mixins/AvatarMixin";
import AvatarGenderTypeSelect from "./AvatarGenderTypeSelect";
import AvatarSkinColorSelect from "./AvatarSkinColorSelect";
import AvatarShapeTypeSelect from "./AvatarShapeTypeSelect";
import AvatarHairColorSelect from "./AvatarHairColorSelect";

export default {
  name: "AvatarBuilder",
  components: {AvatarShapeTypeSelect, AvatarHairColorSelect, AvatarSkinColorSelect, AvatarGenderTypeSelect},
  mixins: [AvatarMixin],
  data() {
    return {
      selectedAvatarElements: [],
      elementsForSelectedCategory: [],
      genderTypeSelected: null,
      skinColorSelected: null,
      hairColorSelected: null,
      shapeTypeSelected: null,
      src: null,
      selectedCategory: null,
      showedChildElements: [],
    }
  },
  computed: {
    avatarElements: function () {
      return this.elementsForSelectedCategory.filter((element) => {
        return element.firstLevel && !element.childAvatarElements.length;
      });
    },
    avatarParentElements: function () {
      return this.elementsForSelectedCategory.filter(element => element.firstLevel && element.childAvatarElements.length);
    },
  },
  methods: {
    getRubbanColor(element) {
      console.log(element)
      if (element && element.hairColor) {
        return 'background-color:' + this.getHairColor(element.hairColor) + ';';
      }
    },
    getHairColor(hairColorId) {
      return this.avatarHairColors.find(hairColor => hairColor['@id'] === hairColorId).color;
    },
    isShown(element, hideParent=false) {
      if(element.childAvatarElements.length >0){
        return  !hideParent;
      }
      if (this.selectedCategory && this.selectedCategory.isSkinRelated) {
        if (this.skinColorSelected !== null) {
          if (element.skinColor !== this.skinColorSelected) {
            return false
          }
        }
      }
      if (element.hairColor != null && this.hairColorSelected != null && (element.hairColor !== this.hairColorSelected)) {
        return false;
      }
      if (element.shapeType != null && this.shapeTypeSelected != null && element.shapeType !== this.shapeTypeSelected) {
        return false;
      }
      if (element.genderType != null && this.genderTypeSelected != null && element.genderType !== this.genderTypeSelected) {
        return false;
      }
      return true;
    },
    selectCategory(category) {
      this.selectedCategory = category;
      this.elementsForSelectedCategory = category.avatarElements.sort((a, b) => {
        return a.avatarElementCategory > b.avatarElementCategory ? 1 : -1;
      });
    },
    selectElement(element) {
      if (element && element.avatarElementCategory) {
        let index = this.selectedAvatarElements.findIndex(selectedElement => selectedElement.category === element.avatarElementCategory)
        if (index >= 0) {
          this.selectedAvatarElements[index].element = {...element};
        } else {
          console.log("ça marche mal")
          this.selectedAvatarElements.push(element);
          this.selectedAvatarElements.push({
            category: element.avatarElementCategory,
            zIndex: element.avatarElementCategory.zIndex,
            element: element
          })
          this.$forceUpdate();
          console.log(this.selectedAvatarElements)
        }
      }
    },
    removeSelectedElementForCategory() {
      if (this.selectedCategory) {
        let index = this.selectedAvatarElements.findIndex(selectedElement => selectedElement.category === this.selectedCategory['@id']);
        if (index >= 0) {
          this.selectedAvatarElements.splice(index, 1);
        }
      }
    }
    ,
    generateRandom(forced = false) {
      if (forced) {
        this.selectedAvatarElements = [];
      }
      if (this.avatarElementCategories.length && !this.selectedAvatarElements.length && this.avatarSkinColors.length && this.avatarHairColors.length && this.avatarGenderTypes.length && this.avatarShapeTypes.length) {
        this.skinColorSelected = this.avatarSkinColors[Math.floor(Math.random() * this.avatarSkinColors.length)]['@id'];
        this.genderTypeSelected = this.avatarGenderTypes[Math.floor(Math.random() * this.avatarGenderTypes.length)]['@id'];
        this.shapeTypeSelected = this.avatarShapeTypes[Math.floor(Math.random() * this.avatarShapeTypes.length)]['@id'];
        this.hairColorSelected = this.avatarHairColors[Math.floor(Math.random() * this.avatarHairColors.length)]['@id'];
        this.avatarElementCategories.forEach((category) => {
          this.selectedCategory = category;
          let avatarElements = category.avatarElements.filter(element => this.isShown(element,true));
          let index = avatarElements ? Math.floor(Math.random() * avatarElements.length) : null;
          this.selectedAvatarElements.push({
            category: category['@id'],
            zIndex: category.zIndex,
            element: index !== null ? avatarElements[index] : index
          })
        });
        this.selectedCategory = null;
      }
    }
  },
  created() {
    this.getAvatarElementCategories();
    this.getAvatarGenderTypes();
    this.getAvatarHairColors();
    this.getAvatarSkinColors();
    this.getAvatarShapeTypes();
  },
  watch: {
    avatarElementCategories: function () {
      this.generateRandom();
    },
    avatarSkinColors: function () {
      this.generateRandom();
    },
    avatarGenderTypes: function () {
      this.generateRandom();
    },
    avatarShapeTypes: function () {
      this.generateRandom();
    },
    avatarHairColors: function () {
      this.generateRandom();
    },
    skinColorSelected: function (after, before) {
      if (before !== after) {
        if (after != null) {
          let saveCurrentCategory = this.selectedCategory;
          this.avatarElementCategories.forEach((category) => {
            if (category.isSkinRelated) {
              this.selectedCategory = category;
              let avatarElements = category.avatarElements.filter(element => this.isShown(element));
              let index = avatarElements ? Math.floor(Math.random() * avatarElements.length) : null;
              this.selectElement(avatarElements[index]);
            }
          });
          this.selectedCategory = saveCurrentCategory;
        }
      }
    }
  }
}
</script>

<style scoped>
/* 45° CSS Ribbon Banner */
aside.ribbon-banner {
  display: block;
  position: absolute;
  top: 50px;
  left: -40px;
  width: 400px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  opacity: 0.7;
  transform: rotate(
      45deg);
  font-size: 24px;
  font-weight: 700;
  color: white;
  border: 1px solid white;
}

.flip-list-move {
  transition: transform 0.5s;
  display: inline-block;
}

.parent {
  border: 1.5mm ridge rgb(161, 28, 28);
}

/deep/ .zoom {

  background-size: cover;
}

/deep/ .zoom:hover {
  -ms-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -webkit-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
}

.avatar {
  position: relative;
  width: 200px;
  height: 200px;
  margin: auto;
}

.avatar img {
  position: absolute;
  top: 0px;
  left: 0px;
}

.clickable {
  cursor: pointer;
}
</style>