import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from "./auth";
import Base from "../layouts/Base";
import Dashboard from "../views/Dashboard";
import content from "./content";
import community from "./community";
import shop from "./shop";
import sales from "./sales";
import players from "./players";
import admins from "./admins";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Base,
    redirect: {name:'Dashboard'},
    children: [
      {
        path: "/",
        name: "DashBoard",
        component: Dashboard
      }
    ]
  },
  auth,content,community,shop,sales,players,admins
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
