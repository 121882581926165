import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/chartist'
import {sync} from "vuex-router-sync";
import VueQuillEditor from "vue-quill-editor";

import Quill from 'quill'
import QuillEmoji from 'quill-emoji';
import "quill-emoji/dist/quill-emoji.css";
Quill.register("modules/emoji", QuillEmoji);


Vue.config.productionTip = false
sync(store, router, {moduleName: 'RouterModule'});

// import vue-quill-editor styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor);

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
