<template>
  <v-row justify="center" align="center" no-gutters>
    <h1 class="white--text mb-5">Couleurs de cheveux</h1>
    <v-row no-gutters>
      <v-card dark v-for="(avatarHairColor,index) in avatarHairColors" width="120px" :key="index">
        <v-card-title :style="'background-color:'+avatarHairColor.color" class="ma-auto text-center">
          <span style="text-shadow: 1px 2px 7px black;">{{ avatarHairColor.color }}</span>
          <v-btn dark small class="red" @click="deleteColor(avatarHairColor)">X</v-btn>
        </v-card-title>
      </v-card>
    </v-row>
    <v-row no-gutters class="mt-5" align="center" justify="center">
      <v-color-picker v-model="colorSelected" mode="hexa"/>
      <v-btn color="primary" class="mt-5" @click="addColor" :disabled="!colorSelected.length">Ajouter</v-btn>
    </v-row>

  </v-row>
</template>

<script>
import AvatarMixin from "../../../mixins/AvatarMixin";
import {mapActions} from "vuex";

export default {
  name: "AvatarHairColors",
  data() {
    return {
      colorSelected: '',
    }
  },
  mixins: [AvatarMixin],
  methods: {
    deleteColor(avatarHairColor) {
      let res = window.confirm("Effacer cette couleur (" + avatarHairColor.color + ") supprimera les avatars liés, êtes vous certain de ne pas faire une bêtise ?");
      console.log(res);
      if (res) {
        this.deleteAvatarHairColor(avatarHairColor);
      }
    },
    addColor() {
      let color = {
        color: this.colorSelected
      }
      this.addAvatarHairColor(color);
    },
    ...mapActions({
      deleteAvatarHairColor: "avatar/deleteAvatarHairColor",
      addAvatarHairColor: "avatar/addAvatarHairColor"
    })
  }
}
</script>

<style scoped>

</style>