import Games from "../views/community/Games";
export default {
    path: '/community',
    name: 'Community',
    component: () => import('../layouts/Base'),
    children: [
        {
            path: "/community/games",
            name: "CommunityGames",
            component: Games,
        },
    ]
};
