import {getField, updateField} from 'vuex-map-fields';

export const notification = {
    namespaced: true,
    state: {
        notifications: [{title: "Votre notification 1", icon: "i-lingerie-gold", notRead: true},
            {title: "Votre notification 2", icon: "i-meeple-gold", notRead: true},
            {title: "Votre notification 3", icon: "i-lingerie-gold", notRead: true},
            {title: "Votre notification 4", icon: "i-lingerie-gold", notRead: true},
            {title: "Votre notification 5", icon: "i-seasons", notRead: false},
            {title: "Votre notification 6", icon: "i-fun", notRead: false},
            {title: "Votre notification 7", icon: "i-lingerie-gold", notRead: false}],
        showNotifications: false,
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
    },
    actions: {}
}