<template>
  <v-dialog
      v-model="dialogPublisher"
      max-width="750px"
      persistent
      v-if="dialogPublisher"
  >
    <v-card dark>
      <v-card-title>
        <span class="headline">Publication ({{
            publicationDuration >= 0 ? publicationDuration + 1 + ' jours' : (this.dateStart ? ' illimité' : 'non publié')
          }} )</span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters align-content="center" align="center" justify="center" class="blue-grey">
          <v-col cols="6" align-self="center" class="text-center">
            Date de début de publication : {{ this.dateStart ? this.dateStart : 'NC' }}
            <v-date-picker
                v-model="dateStart"
                locale="fr-fr"
            />
            <v-btn @click="dateStart=null">Effacer</v-btn>
          </v-col>
          <v-col cols="6" align-self="center" class="text-center transition-fast-in-fast-out" v-show="dateStart">
            Date de fin de publication : {{ this.dateEnd ? this.dateEnd : 'NC' }}
            <v-date-picker
                v-model="dateEnd"
                locale="fr-fr"
                :min="dateStart"

            />
            <v-btn @click="dateEnd=null">Effacer</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="error" @click="save(true)" :disabled="!valid" v-if="unPublish">{{isCommunity?'Refuser':'Dépublier'}}</v-btn>
        <v-btn color="primary" @click="save(true)" :disabled="!valid" v-else>Publier</v-btn>
        <v-btn color="error" @click="cancel">Annuler</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GameMixin from "../../../mixins/GameMixin";
import MessageMixin from "../../../mixins/MessageMixin";
import moment from "moment";

export default {
  name: "GamePublisher",
  mixins: [GameMixin, MessageMixin],
  data() {
    return {
      dateStart: null,
      dateEnd: null
    }
  },
  methods: {
    cancel() {
      this.dateStart = null;
      this.dateEnd = null;
      this.editedItem = -1;
      this.editedItem = this.defaultItem;
      this.dialogPublisher = false;
    },
    updateDates() {
      if (this.dialogPublisher) {
        this.dateStart = this.editedItem.publicationStart ? moment(this.editedItem.publicationStart).format('YYYY-MM-DD') : "";
        this.dateEnd = this.editedItem.publicationEnd ? moment(this.editedItem.publicationEnd).format('YYYY-MM-DD') : "";
      }
    },
    async save(closeOnSave = true) {
      if (this.valid) {
        if (this.editedIndex > -1) {
          await this.updateGame().then(() => {
            if (closeOnSave) {
              this.cancel();
            }
            this.initialize();
            this.$forceUpdate();
          }).catch(() => {
            console.log('hum ou pas')
          });
        }
      } else {
        this.showErrorMessage("Le formulaire n'est pas valide !");
      }
    },
  },
  created() {
    this.updateDates();
  }
  ,
  computed: {
    valid() {
      if (this.dateStart && this.dateEnd) {
        return this.publicationDuration >= 0;
      } else {
        return true;
      }
    },
    unPublish(){
      return (!this.dateStart && !this.dateEnd);
    },
    publicationDuration() {
      if (!this.dateStart || !this.dateEnd) return -1;
      let momentDeb = moment(this.dateStart);
      let momentFin = moment(this.dateEnd);
      let duration = momentFin.diff(momentDeb, "days");
      return duration;
    },
  }
  ,
  watch: {
    dialogPublisher: function () {
      this.updateDates();
    }
    ,
    editedItem: function () {
      this.updateDates();
    }
    ,
    dateStart: function () {
      this.editedItem.publicationStart = this.dateStart;
    }
    ,
    dateEnd: function () {
      this.editedItem.publicationEnd = this.dateEnd;
    }
  }
}
</script>

<style scoped>

</style>