<template>
  <v-row no-gutters>
    <v-col  cols="4" v-for="(gameCategory) in filteredCategories" v-bind:key="gameCategory.id">
    <v-select dark
              v-model="selected"
              :items="gameCategory.gameCategoryElements"
              item-value="@id"
              deletable-chips
              clearable
              chips
              :label="gameCategory.name"
              item-text="name"
              validate-on-blur
              v-on:blur="updateValue"
              multiple>
      <template v-slot:selection="{ item,disabled }">
        <v-chip class="pg-color-dark-moderate-red" dark :disabled="disabled" close
                @click:close="removeFilter(item['@id'])">
          <pg-icon class="mr-5">{{ item.icon }}</pg-icon>
          <span>{{ item.name }}</span>
        </v-chip>
      </template>
      <template v-slot:item="{item,on, attrs}">
        <v-checkbox v-model="attrs.inputValue"/>
        <pg-icon class="mr-5">{{ item.icon }}</pg-icon>
        {{ item.name }}
      </template>
    </v-select>
    </v-col>
  </v-row>
</template>

<script>
import GameMixin from "../../../mixins/GameMixin";
import PgIcon from "../../PGIcon";

export default {
  name: "GameCategoryElementsSelect",
  components: {PgIcon},
  mixins: [GameMixin],
  props: ['value', 'itemValue'],
  data: () => {
    return {
      selected: [],
    }
  },
  computed: {
    filteredCategories() {
      return this.gameFilters.filter(filter => filter.gameCategories.length === 0);
    },
  },
  methods: {
    removeFilter(id) {
      console.log(id)
      this.selected.splice(this.selected.indexOf(id), 1);
    },
    updateValue: function () {
      this.$emit('input', this.selected);
    },
    reset() {
      this.selected = [];
    }
  },
  watch: {
    selected: function () {
      this.$emit('input', this.selected);
    },
    value: function () {
      this.selected = this.value;
    }
  },
  created() {
    if (!this.gameFilters.length) {
      this.getGameFilters();
    }
  },
}
</script>

<style scoped>

</style>