<template>
  <v-container fluid>
    <v-row>
      <v-spacer/>
      <v-col cols="6" lg="3" class="text-right">
      </v-col>
    </v-row>
    <games-data-table/>
  </v-container>
</template>

<script>
import GamesDataTable from "../../components/content/games/GamesDataTable";

export default {
  name: "Games",
  components: {GamesDataTable},
}
</script>

<style scoped>

</style>