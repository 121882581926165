<template>
  <v-container fluid>
    <v-row>
      <v-spacer/>
      <v-col cols="6" lg="3" class="text-right">
      </v-col>
    </v-row>
    <games-data-table-community/>
  </v-container>
</template>

<script>
import GamesDataTableCommunity from "../../components/community/games/GamesDataTableCommunity";
export default {
  name: "Games",
  components: {GamesDataTableCommunity}
}
</script>

<style scoped>

</style>