<template>
  <v-container fluid class="pa-0 black" >
    <v-container fluid class="pa-0 mt-md-16 mt-16">
      <v-row justify="center" no-gutters class="pg-color-black-transparent pt-2">
        <v-col cols="11" sm="6" class=" pa-0 pa-md-12">
          <v-form ref="form" v-model="validForm" class="ma-1 ma-md-10" lazy-validation>
            <v-text-field
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="E-mail"
                class="pg-text-field transparent"
                outlined
                required
                rounded
                dark
                clearable
                placeholder=" "
                ref="login"
            />
            <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Votre mot de passe"
                hint="At least 8 characters"
                counter
                @click:append="show1 = !show1"
                class="pg-text-field"
                outlined
                required
                rounded
                dark
                placeholder=" "
                ref="password"

            />
            <v-btn class="pg-color-grey-80" block :disabled="loading || !validForm" @click="handleLogin">Se connecter
              <v-progress-circular
                  indeterminate
                  color="purple"
                  class="ml-1"
                  v-if="loading"
              ></v-progress-circular>
            </v-btn>
          </v-form>
          <v-alert
              :type=messageType
              dismissible
              v-model="showAlert"
          >{{ message }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import MessageMixin from "@/mixins/MessageMixin";
import AuthAdminMixin from "../mixins/AuthAdminMixin";

export default {
  name: "Login",
  mixins: [AuthAdminMixin, MessageMixin],
  methods: {
    handleLogin() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        this.$store.dispatch('auth/login', {email:this.email,password:this.password}).then(
            () => {
              this.showInfoMessage("Connexion réussie!");
              let direction = 'DashBoard';
              if (this.from.name) {
                direction = this.from.name;
              }
              this.$router.push({name: direction,params:this.from.params});
            }).catch(error => {
          this.loading = false;
          console.log(error);
          let message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          if (error.response.status) {
            switch (error.response.status) {
              case 401: {
                this.showErrorMessage("Le login et le mot de passe sont incorrects");
              }
                break;
              default:
                console.log(message.message)
                this.showErrorMessage("Une erreur est arrivée, merci de réessayer");
            }
          }
        })
        ;
      } else {
        this.loading = false;
        this.showErrorMessage("Il faut rentrer le mail et le mot de passe")
      }
    },
  },
  mounted() {
    this.$refs.login.focus();
  }
}
</script>

<style scoped>
</style>