<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="1000px"
    >
<!--      <template v-slot:activator="{ on, attrs }">-->
<!--        <v-btn color="primary" dark-->
<!--               class="mb-2"-->
<!--               v-bind="attrs"-->
<!--               v-on="on">-->
<!--          <v-icon>mdi-plus-circle</v-icon>-->
<!--          Ajouter une commande-->
<!--        </v-btn>-->
<!--      </template>-->
      <v-card dark v-if="dialog">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                v-if="dialog"
            >
              <v-row justify="center" align="center">
                <v-col cols="12" class="pa-0">
                  <v-text-field
                      v-model="editedItem.name"
                      label="Titre"
                      :rules="nameRules"
                      :counter="255"
                      required
                  />
                </v-col>

                <v-col cols="12" class="pa-0">
                  <label class="label">Description courte</label>
                  <quill-editor class="pg-color-deep-red" v-model="editedItem.description"
                                :options="editorOptions"/>

                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-currency-field v-model="editedItem.price"/>
                </v-col>
                <v-col cols="1">
                  <pg-icon>i-pimentos</pg-icon>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveItem"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card dark>
        <v-card-title class="headline">Voulez vous vraiement supprimer cette commande ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MessageMixin from "../../../mixins/MessageMixin";
import VCurrencyField from "../../VCurrencyField";
import PgIcon from "../../PGIcon";
import OrderMixin from "../../../mixins/sales/OrderMixin";

export default {
  name: "OrderForm",
  components: {PgIcon, VCurrencyField},
  mixins: [OrderMixin, MessageMixin],
  data() {
    return {
      valid: true,
      nameRules: [
        v => !!v || 'Le titre est obligatoire',
        v => (v && v.length >= 5) || 'Le titre doit faire 5 lettres ou plus',
      ],
      descriptionRules: [
        v => !!v || 'La description courte est obligatoire',
        v => (v && v.length >= 20) || 'La description courte doit faire 20 lettres ou plus',
      ],
      pimentosRules: [
        v => !!v || 'Pimentos obligatoire pour cette catégorie',
        v => (v?Number.isInteger(Number.parseInt(v)):true) || 'Seulement des valeurs entières',
        v => (v >= 0) || 'Pas de valeur négative en pimentos'
      ],
    }
  },
  methods: {
    async deleteItemConfirm() {
      if (this.editedIndex > -1) {
        await this.deleteItem().then(() => {
          this.items.splice(this.editedIndex, 1);
        })
      }
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async saveItem(closeOnSave = true) {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          await this.updateItem().then(() => {
            if (closeOnSave) {
              this.close();
            }
            this.$forceUpdate();
          }).catch(() => {
            console.log('hum ou pas')
          });
        } else {
          this.createItem().then(() => {
            if (closeOnSave) {
              this.close();
            }
            this.$forceUpdate();
          })
        }
      } else {
        this.showErrorMessage("Le formulaire n'est pas valide !");
      }
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouvelle commande' : 'Editer commande'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
}
</script>

<style scoped>

</style>