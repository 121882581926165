<template>
  <v-navigation-drawer
      fixed
      temporary
      right
      v-model="showNotifications"
      app
      touchless
  >
    <template v-slot:prepend>
      <v-list-item two-line class="pg-hot-gradient" dark>
        <v-list-item-avatar>
          <pg-icon>i-notif-white</pg-icon>
        </v-list-item-avatar>
        <v-list-item-content >
          <v-list-item-title>Notications</v-list-item-title>
          <v-list-item-subtitle>{{ nbNotificationToRead }} en attente.</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:append>
      <v-btn width="100%" class="pg-color-very-dark-pink" dark @click="showNotifications=!showNotifications">
        <pg-icon s-m>i-close</pg-icon>
        Fermer
      </v-btn>
    </template>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
          v-for="item in notifications"
          :key="item.title"
          link
          @click="showInvitationGameNotification"
      >
        <v-list-item-icon>
          <pg-icon>{{ item.icon }}</pg-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title><span :class="item.notRead?'font-weight-bold':''">{{ item.title }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import PgIcon from "./PGIcon";
import NotificationMixin from "../mixins/NotificationMixin";

export default {
  name: "Notification",
  components: {PgIcon},
  mixins:[NotificationMixin],
  methods:{
    showInvitationGameNotification() {
      this.showNotifications =false;
    }
  }
}
</script>

<style scoped>

</style>