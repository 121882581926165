<template>
  <v-dialog
      v-model="dialogWinner"
      max-width="1000px"
  >
    <v-card dark>
      <v-data-table
          :headers="headers"
          :items="gameContestParticipations"
          :search="search"
          :loading="loading"
          :server-items-length="gameContestParticipationsServerCount"
          sort-by="participant.nickName"
          sort-desc
          class="elevation-15"
          :page="pageParticipations"
          :items-per-page="itemsPerPageParticipations"
          @update:page="onChangePage"
          @update:items-per-page="onChangeItemsPerPage"
      >
        <!-- template des headers !-->
        <template v-slot:top>
        </template>
        <!-- template des éléments !-->
        <template v-slot:item.isAWinner="{ item }">
          <div v-if="item.isAWinner">👑</div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small class="mr-2" @click="deleteItem(item)" color="red">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          Aucun participant trouvé.
        </template>
      </v-data-table>
      <v-row align-content="center" justify="center" no-gutters>
        <v-btn @click="onLuckyDraw">Tirage au sort</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import GameContestMixin from "../../../mixins/content/GameContestMixin";

export default {
  name: "GameContestWinnerSelectionDialog",
  mixins: [GameContestMixin],
  created() {
    this.dialogWinner = false
  },
  data() {
    return {
      dialogWinner: false,
      pageParticipations: 1,
      itemsPerPageParticipations: 5,
      search: '',
      headers: [
        {
          text: '@id',
          align: 'start',
          filterable: false,
          value: '@id',
        },
        {text: 'Pseudo', value: 'participant.nickName', sortable: true},
        {text: 'Mail', value: 'participant.email'},
        {text: 'Gagnant', value: 'isAWinner'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    onLuckyDraw(){
      this.luckyDraw().then(()=>{
        this.reloadParticipants();
      })
    },
    onChangeItemsPerPage(itemsPerPage) {
      this.itemsPerPageParticipations = itemsPerPage;
      this.reloadParticipants();
    },
    onChangePage(page){
      this.pageParticipations = page;
      this.reloadParticipants();
    },
    reloadParticipants() {
      console.log("on reload les participants)")
      this.loadPartipants({
        page: this.pageParticipations,
        itemsPerPage: this.itemsPerPageParticipations
      });
    }
  },
  watch: {
    gameContestWinnerSelected: function () {
      if (this.gameContestWinnerSelected != null) {
        this.reloadParticipants();
        this.dialogWinner = true;
      } else {
        this.dialogWinner = false;
      }
    },
    dialog: function () {
      if (!this.dialogWinner) {
        this.gameContestWinnerSelected = null;
      }
    }
  },
  beforeDestroy() {
    this.gameContestWinnerSelected = null;
  }
}
</script>

<style scoped>

</style>