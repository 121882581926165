<template>
  <v-container fluid>
    <v-row>
      <v-spacer/>
      <v-col cols="6" lg="3" class="text-right">
      </v-col>
    </v-row>
    <games-contest-data-table/>
  </v-container>
</template>

<script>
import GamesContestDataTable from "../../components/content/games_contest/GamesContestDataTable";
export default {
  name: "GamesContest",
  components: {GamesContestDataTable}
}
</script>

<style scoped>

</style>