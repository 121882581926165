<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="1000px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark
               class="mb-2"
               v-bind="attrs"
               v-on="on"
               v-if="!isCommunity">
          <v-icon>mdi-plus-circle</v-icon>
          Ajouter un jeu
        </v-btn>
      </template>
      <v-card dark v-if="dialog">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                v-if="dialog"
            >
              <v-row>
                <v-col cols="12" class="pa-0">
                  <v-text-field
                      v-model="editedItem.title"
                      label="Titre"
                      :rules="titleRules"
                      :counter="255"
                      required
                  />
                </v-col>
                <v-col cols="6" class="pa-0">
                  <GameTypesSelect v-model="editedItem.gameType" item-value="@id"/>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <GameCategoryElementsSelect v-model="editedItem.categoryElements" item-value="@id"/>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <!--                  <v-textarea-->
                  <!--                      v-model="editedItem.descriptionShort"-->
                  <!--                      label="Description courte"-->
                  <!--                      :counter="255"-->
                  <!--                      :rules="descriptionShortRules"-->
                  <!--                      rows="3"-->
                  <!--                      required-->
                  <!--                      disabled-->
                  <!--                      v-html="editedItem.descriptionShort"-->
                  <!--                  />-->
                  <label class="label">Description courte</label>
                  <quill-editor class="pg-color-deep-red" v-model="editedItem.descriptionShort"
                                :options="editorOptions"/>

                </v-col>
                <v-col cols="12" class="pa-0">
                  <!--                  <v-textarea-->
                  <!--                      v-model="editedItem.descriptionLong"-->
                  <!--                      label="Description longue"-->
                  <!--                      rows="8"-->
                  <!--                      required-->
                  <!--                      :rules="descriptionLongRules"-->
                  <!--                  />-->
                  <label class="label">Description longue</label>
                  <quill-editor class="pg-color-deep-red" v-model="editedItem.descriptionLong"
                                :options="editorOptions"/>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="pa-0"
                >
                  <v-text-field
                      v-model="editedItem.pointsReward"
                      label="Pimentos Gain"
                      required
                      type="number"
                      step="1"
                      :rules="pimentosRules"
                      @input="checkRewarcInteger"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="pa-0"
                >
                  <v-text-field
                      v-model="editedItem.pointsCost"
                      label="Pimentos Coût"
                      required
                      type="number"
                      step="1"
                      :rules="pimentosRules"
                      @input="checkCostInteger"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    class="pa-0"
                >
                  <GameDeviceSelect v-model="editedItem.gameDevices" :items="editedItem.gameDevices"/>
                </v-col>
                <v-col cols="12">
                  <GameDurationSlider v-model="editedItem.duration"/>
                </v-col>
              </v-row>
              <v-col cols="12">
                <ImageUploader v-model="editedItem.image" :upload-function="uploadGamePicture"
                               :image-owner="{type:'game','id':editedItem.id}" v-if="editedIndex>-1"
                               v-on:update:image="saveGame(false)"/>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveGame"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card dark>
        <v-card-title class="headline">Are you sure you want to delete this item? (FAKE)</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GameMixin from "../../../mixins/GameMixin";
import GameTypesSelect from "../game_types/GameTypesSelect";
import GameCategoryElementsSelect from "../game_category_elements/GameCategoryElementsSelect";
import GameDeviceSelect from "../game_devices/GameDevicesSelect";
import ImageUploader from "../../ImageUploader";
import MessageMixin from "../../../mixins/MessageMixin";
import QuillEditorMixin from "../../../mixins/QuillEditorMixin";
import GameDurationSlider from "./GameDurationSlider";

export default {
  name: "GameForm",
  components: {GameDurationSlider, ImageUploader, GameDeviceSelect, GameCategoryElementsSelect, GameTypesSelect},
  mixins: [GameMixin, MessageMixin, QuillEditorMixin],
  data() {
    return {
      valid: true,
      titleRules: [
        v => !!v || 'Le titre est obligatoire',
        v => (v && v.length >= 5) || 'Le titre doit faire 5 lettres ou plus',
      ],
      descriptionShortRules: [
        v => !!v || 'La description courte est obligatoire',
        v => (v && v.length >= 10) || 'La description courte doit faire 10 lettres ou plus',
      ],
      descriptionLongRules: [
        v => !!v || 'La description courte est obligatoire',
        v => (v && v.length >= 20) || 'La description courte doit faire 20 lettres ou plus',
      ],
      pimentosRules: [
        v => (v!=='') || 'Le nombre de pimentos est obligatoire',
        v => (Number.isInteger(Number.parseInt(v))) || 'Seulement des valeurs entières',
        v => (v >= 0) || 'Pas de valeur négative en pimentos'
      ],
    }
  },
  methods: {
    async deleteItemConfirm() {
      if (this.editedIndex > -1) {
        await this.deleteGame().then(() => {
          this.games.splice(this.editedIndex, 1);
        })
      }
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        console.log("DELETE l'image si UPLOAD!")
      })
    },
    async saveGame(closeOnSave = true) {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          await this.updateGame().then(() => {
            if (closeOnSave) {
              this.close();
            }
            this.$forceUpdate();
          }).catch(() => {
            console.log('hum ou pas')
          });
        } else {
          this.createGame().then(() => {
            this.$forceUpdate();
          })
        }
      } else {
        this.showErrorMessage("Le formulaire n'est pas valide !");
      }
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    checkRewarcInteger(valPimentos) {
      this.editedItem.pointsReward = Number(valPimentos);
    },
    checkCostInteger(valPimentos) {
      this.editedItem.pointsCost = Number(valPimentos);
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouveau jeu' : 'Editer jeu'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
}
</script>

<style scoped>

</style>