<template>
  <v-row justify="center">
    <h1 class="white--text">Avatars</h1>
    <v-expansion-panels popout dark>
      <v-expansion-panel
          v-for="(avatarElementCategory,index) in avatarElementCategories"
          :key="index"
      >
        <v-expansion-panel-header>{{ avatarElementCategory.title }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <avatar-element-category :index="index"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-btn color="primary" @click="addCategory">Ajouter</v-btn>
  </v-row>
</template>

<script>

import AvatarElementCategory from "./AvatarElementCategory";
import AvatarMixin from "../../../mixins/AvatarMixin";

export default {
  name: "AvatarElementCategories",
  components: {AvatarElementCategory},
  mixins: [AvatarMixin],
  methods:{
    addCategory(){
      this.avatarElementCategories.push({
        '@id':null,
        '@type':"AvatarElementCategory",
        avatarElements:[],
        isClothing:false,
        isSkinRelated:false,
        title:"Nouvelle catégorie",
        zIndex:0,
      })
    }
  }
}
</script>

<style scoped>

</style>