import AuthService from '../../services/auth/auth.service';

const token = localStorage.getItem('token');

const initialState = token
    ? {status: {loggedIn: true}, token: token}
    : {status: {loggedIn: false}, token: null};

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({commit}, token) {
            return AuthService.login(token).then(
                token => {
                    commit('loginSuccess', token);
                    return Promise.resolve(token);
                }).catch(
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                });
        },
        logout({commit}) {
            AuthService.logout();
            commit('logout');
        },
        register({commit}, payload) {
            return AuthService.register(payload).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                }).catch(
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                });
        },
        verify({commit}, payload) {
            return AuthService.verification(payload).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                }).catch(
                error => {
                    console.log(error)
                    return Promise.reject(error);
                });
        }
    },
    mutations: {
        loginSuccess(state, payload) {
            state.status.loggedIn = true;
            state.token = payload.token;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.token = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.token = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    },
    getters: {
        loggedIn: state => {
            return state.status.loggedIn;
        },
        lastPage: state => {
            return state.status.lastPage;
        },
        lastParams: state => {
            return state.status.lastParams;
        },
    }
};