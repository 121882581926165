import axios from "../plugins/axios";
import authHeader from "./auth/auth-headers";
import store from "../store";

const API_URL = process.env.VUE_APP_API_URL;

class APIService {
    async post(path, data, config) {
        authHeader(data);
        let response;
        try {
            response = await axios.post(API_URL + path, data, config);
        } catch (e) {
            console.log(e)
            if (e.response && e.response.status === 401) {
                store.dispatch("message/showErrorMessage", "Votre session a expirée.")
                store.dispatch("auth/logout");
            } else {
                store.dispatch("message/showErrorMessage", e);
            }
            return Promise.reject(e.response);
        }
        return Promise.resolve(response);
    }

    async put(path, data) {
        authHeader(data);
        let response;
        try {
            response = await axios.put(API_URL + path, data, {
                headers: {
                    'Content-Type': 'application/ld+json'
                }
            });
        } catch (e) {
            console.log(e)
            if (e.response && e.response.status === 401) {
                store.dispatch("message/showErrorMessage", "Votre session a expirée.")
                store.dispatch("auth/logout");
            } else {
                store.dispatch("message/showErrorMessage", e);
            }
            return Promise.reject(e.response);
        }
        return Promise.resolve(response);
    }

    async get(path, data = {}) {
        authHeader(data);
        let response;
        try {
            response = await axios.get(API_URL + path);
        } catch (e) {
            console.log(e)
            if (e.response && e.response.status === 401) {
                store.dispatch("message/showErrorMessage", "Votre session a expirée.")
                store.dispatch("auth/logout");
            } else {
                store.dispatch("message/showErrorMessage", e);
            }
            return Promise.reject(e.response);
        }
        return Promise.resolve(response);
    }

    async delete(path, data = {}) {
        authHeader(data);
        let response;
        try {
            response = await axios.delete(API_URL + path);
        } catch (e) {
            console.log(e);
            if (e.response && e.response.status === 401) {
                store.dispatch("message/showErrorMessage", "Votre session a expirée.")
                store.dispatch("auth/logout");
            } else {
                store.dispatch("message/showErrorMessage", e);
            }
            return Promise.reject(e.response);
        }
        return Promise.resolve(response);
    }

    async getItems(entity, pagination = {}) {
        let url = '/' + entity + '?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 50;
        url += 'page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage
        }
        if(pagination.filters != undefined){
            url+= pagination.filters;
        }
        return await this.get(url);
    }

    async createItem(entity, item) {
        return await this.post('/' + entity, item);
    }

    async updateItem(item) {
        return await this.put(item['@id'], item);
    }

    async deleteItem(item) {
        return await this.delete(item['@id']);
    }
}

export default new APIService();