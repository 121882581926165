<template>
  <v-select :items="gameTypes"
            :item-value="itemValue"
            item-text="name"
            v-model="selected"
            dark
            chips
            label="Type de jeu"
            />
</template>

<script>
import GameMixin from "../../../mixins/GameMixin";

export default {
  name: "GameTypesSelect",
  mixins: [GameMixin],
  props: ['value','itemValue'],
  data: () => {
    return {
      selected: null,
    }
  },
  watch:{
    selected : function (){
    this.$emit('input',this.selected);
    },
    value : function (){
      this.selected = this.value;
    }
  }
}
</script>

<style scoped>

</style>