import {getField, updateField} from 'vuex-map-fields';
import GameService from "../../../services/game.service";

export const gameStages = {
    namespaced: true,
    state: {
        stages: [],
        stagesToBeSaved: [],
        dialogAction: false,
        stageDefault: {
            game: "",
            gameStageActions: [],
            gameStageInstructions: [],
            id: null,
            isFinal: false,
            stageNumber: null
        },
        editedGameStageAction: null,
        instructionDefault: {
            playerNumber: 0,
            text: "",
        },
        nbPlayers: 0,
        editedPlayerInteractionFrom: null,
        dialogPlayerInteractionFrom: false,
        gameStagePlayerInteractionTypes: [],
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_STAGES(state, payload) {
            let stages = payload.stages;
            state.nbPlayers = payload.nbPlayers;
            stages.forEach(stage => {
                let gameStageInstructions = [];
                for (let i = 0; i < state.nbPlayers; i++) {
                    let instruction = stage.gameStageInstructions.find(instruction => {
                        return instruction.playerNumber === (i + 1)
                    })
                    if (!instruction) {
                        instruction = Object.assign({}, state.instructionDefault);
                        instruction.playerNumber = i + 1;
                    }
                    gameStageInstructions[i] = instruction;
                }
                stage.gameStageInstructions = gameStageInstructions;
            })
            state.stages = stages;
        },
        SET_STAGE_SAVED(state, stage) {
            let index = state.stagesToBeSaved.findIndex(stageNumber => stage.stageNumber === stageNumber);
            if (index >= 0) {
                state.stagesToBeSaved.splice(index, 1);
            }
            index = state.stages.findIndex(stageStored => stage.stageNumber === stageStored.stageNumber)
            console.log(stage)
            console.log(index)
            console.log(state.stages[index])
            Object.assign(state.stages[index], stage);
        },
        ADD_STAGE(state, game) {
            let emptyStage = Object.assign({}, state.stageDefault);
            emptyStage.stageNumber = state.stages.length + 1;
            let gameStageInstructions = [];
            for (let i = 0; i < state.nbPlayers; i++) {
                let instruction = Object.assign({}, state.instructionDefault)
                instruction.playerNumber = i + 1;
                gameStageInstructions.push(instruction);
            }
            emptyStage.gameStageInstructions = gameStageInstructions;
            emptyStage.game = game;
            state.stages.push(emptyStage);
            state.stagesToBeSaved.push(state.stages.length);
        },
        DELETE_STAGE(state, index) {
            state.stages.splice(index, 1);
            state.stages.forEach((stage, index) => {
                stage.stageNumber = index + 1;
            })
        },
        DELETE_STAGE_ACTION(state) {
            let actionEdited = state.editedGameStageAction;
            state.stages.forEach(stage => {
                let index = stage.gameStageActions.findIndex(action => action.id === actionEdited.id)
                stage.gameStageActions.splice(index, 1);
            })
        },
        DELETE_PLAYER_INTERACTION_FROM(state) {
            let interactionEdited = state.editedPlayerInteractionFrom;
            console.log(state.editedPlayerInteractionFrom);
            state.stages.forEach(stage => {
                let index = stage.gameStagePlayerInteractionsFrom.findIndex(interaction => interaction.id === interactionEdited.id);
                stage.gameStagePlayerInteractionsFrom.splice(index, 1);
            })
        },
        SET_PLAYER_INTERACTION_TYPES(state, payload) {
            state.gameStagePlayerInteractionTypes = payload;
        },
    },
    actions: {
        async getGameStages({commit}, payload) {
            let nbPlayers = payload.nbPlayersMax ? payload.nbPlayersMax : payload.nbPlayersMin;
            return new Promise((resolve, reject) => {
                GameService.getStages(payload).then(response => {
                    let payloadStages = {
                        stages: response.data['hydra:member'],
                        nbPlayers: nbPlayers
                    }
                    commit('SET_STAGES', payloadStages);
                    return resolve();
                }).catch(error => {
                    return reject(error);
                });
            })
        },
        async addGameStage({commit}, game) {
            return new Promise((resolve) => {
                commit('ADD_STAGE', game['@id']);
                return resolve();
            })
        },
        async deleteGameStage({commit, dispatch}, stage) {
            let index = stage.stageNumber - 1;
            return new Promise((resolve, reject) => {
                if(!stage['@id']){
                    commit('DELETE_STAGE', index);
                    return resolve(true);
                }
                GameService.deleteStage(stage).then(response => {
                    commit('DELETE_STAGE', index);
                    dispatch("message/showSuccessMessage", "Level supprimé avec succès!", {root: true})
                    return resolve(response.data);
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de suppression du level", {root: true})
                    return reject(error);
                });
            })
        },
        async deleteGameStageAction({commit}) {
            return new Promise((resolve) => {
                commit('DELETE_STAGE_ACTION');
                return resolve();
            })
        },
        async deletePlayerInteractionFrom({commit}) {
            return new Promise((resolve) => {
                commit('DELETE_PLAYER_INTERACTION_FROM');
                return resolve();
            })
        },
        async saveGameStage({dispatch, commit}, stage) {
            return new Promise((resolve, reject) => {
                if (!stage.id) {
                    GameService.createStage(stage).then(response => {
                        commit('SET_STAGE_SAVED', response.data);
                        dispatch("message/showSuccessMessage", "Level ajouté avec succès!", {root: true})
                        return resolve(response.data);
                    }).catch(error => {
                        dispatch("message/showErrorMessage", "Erreur d'ajout du level", {root: true})
                        return reject(error);
                    });
                } else {
                    GameService.updateStage(stage).then(response => {
                        commit('SET_STAGE_SAVED', response.data);
                        dispatch("message/showSuccessMessage", "Level modifié avec succès!", {root: true})
                        return resolve(response.data);
                    }).catch(error => {
                        dispatch("message/showErrorMessage", "Erreur de modification du level", {root: true})
                        return reject(error);
                    });
                }
            })
        },
        async getGameStagePlayerInteractionTypes({commit, state}) {
            return new Promise((resolve, reject) => {
                if (state.gameStagePlayerInteractionTypes.length > 0) {
                    return resolve(state.gameStagePlayerInteractionTypes);
                }
                GameService.getGameStagePlayerInteractionTypes().then(response => {
                    commit('SET_PLAYER_INTERACTION_TYPES', response.data['hydra:member']);
                    return resolve();
                }).catch(error => {
                    return reject(error);
                });
            })
        },
    }
}