<template>
  <v-container fluid>
    <v-row>
      <v-spacer/>
      <v-col cols="6" lg="3" class="text-right">
      </v-col>
    </v-row>
    <product-categories-data-table/>
  </v-container>
</template>

<script>


import ProductCategoriesDataTable from "../../components/shop/product_categories/ProductCategoriesDataTable";
export default {
  name: "ProductCategories",
  components: {ProductCategoriesDataTable},
}
</script>

<style scoped>

</style>