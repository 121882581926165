<template>
  <v-container fluid>
    <v-row>
      <v-spacer/>
      <v-col cols="6" lg="3" class="text-right">
      </v-col>
    </v-row>
    <admins-data-table/>
  </v-container>
</template>

<script>
import AdminsDataTable from "../../components/admins/manager/AdminsDataTable";

export default {
  name: "AdminsManager",
  components: {AdminsDataTable}
}
</script>

<style scoped>

</style>