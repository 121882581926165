<template>
  <div>
    <v-dialog
        v-model="dialogGameStageEditor"
        v-if="dialogGameStageEditor"
        fullscreen
    >
      <game-stages-action-editor/>
      <game-stages-player-interaction-editor/>
      <v-dialog v-model="dialogConfirmNotSaving" max-width="350">
        <v-card>
          <v-card-title class="headline">
            Quitter sans sauvegarder ?
          </v-card-title>
          <v-card-text>
            Les niveaux suivants ont besoin d'être sauvé pour ne pas perdre les changements :
            <span v-for="stageNumber in stagesToBeSaved" v-bind:key="stageNumber">{{ stageNumber }}</span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="confirmNotSaving">Perdre les changements</v-btn>
            <v-btn color="cyan" dark @click="dialogConfirmNotSaving = false">Annuler</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card dark class="fill-height">
        <v-img src="~@/assets/game-level.png" class="text-center" contain>
          <v-card-title class="cyan-transparent elevation-10">
            Éditeur de niveaux de jeu : {{ editedItem.title }}
            <v-spacer/>
            <v-btn @click="close">Fermer</v-btn>
          </v-card-title>
          <v-expansion-panels>
            <v-expansion-panel
                v-for="(stage) in stages"
                :key="stage.id"
            >
              <v-expansion-panel-header :class="!stage.isFinal?'platform':'platform-final'">
                <v-row>
                  <v-col cols="10">
                    <h1 class="">Level {{ stage.stageNumber }}<span v-if="isStageNeedToBeSaved(stage)"> *</span></h1>
                  </v-col>
                  <v-col cols="2" class="text-right">
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="black-transparent">
                <v-row>
                  <v-col cols="3" v-for="i in nbPlayers" v-bind:key="i">
                    <v-card dark class="cyan-transparent">
                      <v-card-title><h2 class="text-center" style="width: 100%">Joueur {{ i }}</h2></v-card-title>
                      <quill-editor class="pg-color-deep-red" v-model="stage.gameStageInstructions[i-1].text"
                                    :options="editorOptions" @change="setStageToBeSaved(stage)"/>
                      <v-row no-gutters class="text-left" v-if="isActionInteractionType">
                        <v-col cols="12">
                          <span class="subtitle-2 pr-2">Boutons d'actions :</span>
                          <v-btn @click="editAction(action,stage)" v-for="(action) in getActions(stage,i)"
                                 v-bind:key="action.id">"{{ action.label }}"
                          </v-btn>
                          <v-btn fab small @click="addAction(stage,i)">
                            <v-icon>mdi-plus-circle</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="text-left" v-if="isActionInteractionType">
                        <v-col cols="12">
                          <span class="subtitle-2 pr-2">Intéractions :</span>
                          <v-btn @click="editPlayerInteractionFrom(interaction,stage)"
                                 v-for="(interaction) in getPlayerInteractionsFrom(stage,i)"
                                 v-bind:key="interaction.id">
                            <pg-icon mono>i-profil</pg-icon>
                            {{ interaction.playerNumberFrom }} >
                            {{ interaction.playerNumberDestination ? interaction.playerNumberDestination : '?' }}
                            <pg-icon mono>i-profil</pg-icon>
                          </v-btn>
                          <v-btn fab small @click="addPlayerInterActionFrom(stage,i)">
                            <v-icon>mdi-plus-circle</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-navigation-drawer permanent expand-on-hover right>
                    <v-list dense nav>
                      <v-list-item @click="setGameStageFinal(stage)">
                        <v-list-item-icon>
                          <v-icon :color="stage.isFinal?'yellow':'grey'">mdi-check-decagram-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Niveau final</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="saveGameStage(stage)" :disabled="!isStageNeedToBeSaved(stage)">
                        <v-list-item-icon>
                          <v-icon :color="isStageNeedToBeSaved(stage)?'green':'grey'">mdi-content-save-edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Sauvegarder le level</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="deleteGameStage(stage)">
                        <v-list-item-icon>
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Supprimer le level</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-navigation-drawer>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row no-gutters justify="end" align-content="end" align="end">
            <v-btn color="brown" v-on:click.prevent="addGameStage(editedItem)">
              <v-icon>mdi-server-plus</v-icon>
            </v-btn>
          </v-row>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GameMixin from "../../../mixins/GameMixin";
import GameStagesMixin from "../../../mixins/GameStagesMixin";
import GameStagesActionEditor from "./GameStagesActionEditor";
import QuillEditorMixin from "../../../mixins/QuillEditorMixin";
import PgIcon from "../../PGIcon";
import GameStagesPlayerInteractionEditor from "./GameStagesPlayerInteractionEditor";


export default {
  name: "GameStagesEditor",
  components: {GameStagesPlayerInteractionEditor, PgIcon, GameStagesActionEditor},
  mixins: [GameMixin, GameStagesMixin, QuillEditorMixin],
  data: () => {
    return {
      dialogConfirmNotSaving: false,
    }
  },
  methods: {

    close() {
      if (this.hasStageToBeSaved) {
        this.dialogConfirmNotSaving = true;
      } else {
        this.dialogGameStageEditor = false;
        this.dialogPlayerInteractionFrom = false;
        this.editedIndex = -1;
        this.editedItem = this.defaultItem;
        this.stages = [];
        this.stagesToBeSaved = [];
      }
    },
    confirmNotSaving() {
      this.stagesToBeSaved = [];
      this.close();
    },
    getInstructions(stageNumber, playerNumber) {
      if (!this.stages[stageNumber - 1]) return null;
      return this.stages[stageNumber - 1].gameStageInstructions.find(instruction => {
        return instruction.playerNumber === playerNumber;
      })
    },
    getActions(stage, playerNumber) {
      return stage.gameStageActions.filter(action => {
        return action.playerNumber === playerNumber
      })
    },
    getPlayerInteractionsFrom(stage, playerNumber) {
      if(!stage.gameStagePlayerInteractionsFrom){
        return [];
      }
      return stage.gameStagePlayerInteractionsFrom.filter(interaction => {
        return interaction.playerNumberFrom === playerNumber
      })
    },
    addAction(stage, playerNumber) {
      let action = {
        label: "à éditer",
        playerNumber: playerNumber,
      }
      stage.gameStageActions.push(action);
      this.editAction(action, stage);
      return action;
    },
    editAction(action, stage) {
      this.editedGameStageAction = action;
      this.dialogAction = true;
      this.setStageToBeSaved(stage);
    },
    editPlayerInteractionFrom(interaction, stage) {
      this.editedPlayerInteractionFrom = interaction;
      this.dialogPlayerInteractionFrom = true;
      this.setStageToBeSaved(stage);
    },
    addPlayerInterActionFrom(stage, playerNumber) {
      let interaction = {
        playerNumberFrom: playerNumber,
        gameStageFrom: stage['@id'],
        labelFrom: "à éditer",
        labelDestination: "à éditer",
      }
      stage.gameStagePlayerInteractionsFrom.push(interaction);
      this.editPlayerInteractionFrom(interaction, stage);
      return interaction;
    },
    isStageNeedToBeSaved(stage) {
      return this.stagesToBeSaved.indexOf(stage.stageNumber) >= 0;
    },
    setGameStageFinal(stage) {
      stage.isFinal = !stage.isFinal;
      this.setStageToBeSaved(stage);
    }
  },
  computed: {
    isActionInteractionType() {
      let typeScenario = this.gameTypes.find(type => type.code === "scenarios");
      return this.editedItem.gameType === typeScenario['@id'];
    }
  },
  created() {

  },
  watch: {
    editedIndex: function () {
      if (this.dialogGameStageEditor && this.editedIndex >= 0) {
        this.getGameStages(this.editedItem);
      }
    }
  }
}
</script>

<style scoped>

.cyan-transparent {
  background-color: rgba(0, 165, 227, 0.7);
}

.black-transparent {
  background-color: rgba(2, 24, 33, 0.45);
}

.platform {
  background-image: url("~@/assets/game-platform.png");
  background-repeat-x: repeat;
  height: 96px;
  text-shadow: 4px 4px darkviolet;
}

.platform-final {
  background-repeat-x: repeat;
  height: 96px;
  text-shadow: 4px 4px darkviolet;
  background-image: url("~@/assets/game-platform-final.png") !important;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}
</style>