<template>
  <v-dialog v-model="dialogAction" @click:outside="onClose" max-width="500px">
    <v-card dark v-if="editedGameStageAction">
      <v-card-title>Éditeur d'action : {{ editedGameStageAction.label }}</v-card-title>
      <v-card-text>
        <v-text-field v-model="editedGameStageAction.label" label="Label du bouton"/>
        Type action : aller au level :
        <v-select v-model="editedGameStageAction.goToStage" :items="onlySavedStages" item-value="@id" item-text="stageNumber"></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="cyan" @click="close">Fermer</v-btn>
        <v-btn color="error" @click="onDelete">Supprimer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GameStagesMixin from "../../../mixins/GameStagesMixin";

export default {
  name: "GameStagesActionEditor",
  mixins: [GameStagesMixin],
  methods: {
    close(){
      this.dialogAction=false;
      this.onClose();
    },
    onClose() {
      this.editedGameStageAction = null;
    },
    onDelete(){
      this.deleteGameStageAction();
      this.close();
    }
  }
}
</script>

<style scoped>

</style>