<template>
  <material-card
      v-bind="$attrs"
      class="v-card--material-stats"
      v-on="$listeners"
  >
    <v-card
        slot="offset"
        :class="`elevation-${elevation}`"
        :color="color"
        class="pa-4"
        dark
    >
      <pg-icon x-x-l>{{ icon }}</pg-icon>
    </v-card>
    <v-row align-content="end">
      <v-spacer/>
      <v-row align-content="end">
        <v-col cols="12" class="text-right">{{ title }}</v-col>
        <v-col cols="12" class="text-right">
          <h3
              class="title display-1 font-weight-light text-right">
            {{ value }} <small>{{ smallValue }}</small>
          </h3></v-col>
      </v-row>
    </v-row>
    <template slot="actions">
      <v-icon
          :color="subIconColor"
          size="20"
          class="mr-2"
      >
        {{ subIcon }}
      </v-icon>
      <router-link :to="to" v-if="to">
      <span
          :class="subTextColor"
          class="caption font-weight-light"
          style="text-decoration: none!important;"
          v-text="subText"
      />
      </router-link>
      <span
          :class="subTextColor"
          class="caption font-weight-light"
          v-text="subText"
          v-else
      />
    </template>
  </material-card>
</template>

<script>
import Card from './Card'
import MaterialCard from "./Card";
import PgIcon from "../PGIcon";

export default {
  name: "MaterialStatsCard",
  components: {PgIcon, MaterialCard},
  inheritAttrs: false,

  props: {
    ...Card.props,
    icon: {
      type: String,
      required: true
    },
    subIcon: {
      type: String,
      default: undefined
    },
    subIconColor: {
      type: String,
      default: undefined
    },
    subTextColor: {
      type: String,
      default: undefined
    },
    subText: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    },
    smallValue: {
      type: String,
      default: undefined
    },
    to: {
      type: Object,
      default: undefined
    }
  }
}
</script>

<style lang="scss">
.v-card--material-stats {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .v-offset {
    display: inline-block;
    flex: 0 1;
    margin-top: 0;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0 !important;
    max-width: auto;
    padding: 0 16px 0;
  }

  .v-card {
    border-radius: 4px;
    flex: 0 1 auto;
  }

  .v-card__text {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }

  .v-card__actions {
    flex: 1 0 100%;
  }
}
</style>
