import {getField, updateField} from 'vuex-map-fields';
import ApiService from "../../../services/api.service";

const entity = 'payments';

export const payment = {
    namespaced: true,
    state: {
        items: [],
        totalItems: 0,
        loading: false,
        dialog: false,
        dialogDelete: false,
        editedItem: {
            name: "",
            description: "",
            price: null,
            category: null,
            awardPoints: null
        },
        editedIndex: -1,
        defaultItem: {
            name: "",
            description: "",
            price: null,
            category: null,
            awardPoints: null
        },
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_ITEMS(state, payload) {
            state.items = payload.items;
            state.totalItems = payload.totalItems;
        },
        UPDATE_ITEM(state, payload) {
            Object.assign(state.items[payload.index], payload.item);
        },
        ADD_ITEM(state, payload) {
            state.items.push(payload.item);
            state.editedItem = payload.item;
            state.editedIndex = state.items.length - 1;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
    },
    actions: {
        async getItems({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let pagination = payload.pagination;
                commit('SET_LOADING', true);
                ApiService.getItems(entity,pagination).then(response => {
                    let payload = {
                        items: response.data['hydra:member'],
                        totalItems: response.data['hydra:totalItems']
                    }
                    commit('SET_ITEMS', payload)
                    return resolve();
                }).catch(error => {
                    console.error(error);
                    dispatch("message/showErrorMessage", "Erreur de chargement des commandes", {root: true})
                    reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async updateItem({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let index = state.editedIndex;
                let item = {...state.editedItem};
                ApiService.updateItem(item).then(() => {
                    commit('UPDATE_ITEM', {index: index, item: item});
                    dispatch("message/showSuccessMessage", "Commande mise à jour avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de modification de la commande", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            });
        },
        async deleteItem({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let item = {...state.editedItem};
                ApiService.deleteItem(item).then(() => {
                    dispatch("message/showSuccessMessage", "Commande  effacée avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de modification de la commande", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });

            })
        },
        async createItem({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let item = {...state.editedItem};
                ApiService.createItem(entity,item).then((response) => {
                    commit('ADD_ITEM', {item: response.data});
                    dispatch("message/showSuccessMessage", "Commande ajoutée avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur d'ajout de la commande", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
    }
}