<template>
  <v-dialog
      v-model="dialog"
      max-width="375"
  >
    <v-card>
      <v-img src="~@/assets/banner-transparent-black.png" contain dark class="pa-5 ma-5"/>
      <v-card-title class="headline">
        Voulez-vous installer Private Games en tant qu'application PWA ?
      </v-card-title>
      <v-card-text>
        Une application Progressive Wep App (PWA) est un format léger d'application avec les mêmes contenus et
        fonctionnalités que sur un store classique, mais beaucoup moins d'encombrement. Fonctionne sur
        PC/MAC/Android/iPhone/iPad/...
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            @click="addToHomeScreen"
        >
          Oui super !
        </v-btn>
        <v-btn
            text
            @click="refuseHomeScreen"
        >
          Pas pour le moment
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';
import {mapActions} from "vuex";

const {mapFields} = createHelpers({
  getterType: 'pwa/getField',
  mutationType: 'pwa/updateField'
});

export default {
  name: "PWAManager",
  data: function () {
    return {
      dialog: false,
    }
  },
  computed: {
    ...mapFields(['isPWAReady','lastAsked'])
  },
  methods: {
    ...mapActions({
      updateLastAskedPWA: "pwa/updateLastAskedPWA",
    }),
    isPWAToShow() {
      if (!this.lastAsked) {
        return true;
      }
      let dateDiff = Date.now() - this.lastAsked;
      return parseInt(dateDiff / (24 * 3600 * 1000)) > 6;
    },
    addToHomeScreen() {
      // Show the prompt
      this.dialog = false;
      this.isPWAReady.prompt();
      // Wait for the user to respond to the prompt
      this.isPWAReady.userChoice
          .then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            this.isPWAReady = null;
          });

    },
    refuseHomeScreen() {
      this.dialog = false;
      this.isPWAReady = null;
    }
  },
  created() {

    console.log("Adding event listener: checking PWA");
    window.addEventListener('beforeinstallprompt', e => {
      console.log('App is PWA ready')
      e.preventDefault();
      this.isPWAReady = e;
      if (this.isPWAToShow()) {
        this.dialog = true;
        this.updateLastAskedPWA();
      }
    });
  }
}
</script>

<style scoped>

</style>