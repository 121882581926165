import {createHelpers} from 'vuex-map-fields';
import {mapActions} from "vuex";

const {mapFields} = createHelpers({
    getterType: 'game/getField',
    mutationType: 'game/updateField'
});

export default {
    computed: {
        ...mapFields(['dialog', 'loading', 'gameTypes', 'gamesByType', 'editedItem', 'defaultItem', 'games',
            'totalGames', 'type', 'editedIndex', 'selectedGameType', 'gameFilters', 'selectedFilters', 'dialogDelete',
            'dialogPublisher', 'dialogGameStageEditor', 'isCommunity', 'filterIsValidatedCommunity',
            'filterIsRefusedCommunity', 'filterIsWaiting', 'page', 'itemsPerPage']),
        nbPlayers() {
            return this.editedItem.nbPlayersMax ? this.editedItem.nbPlayersMax : this.editedItem.nbPlayersMin;
        }
    },
    methods: {

        ...mapActions({
            getGameFilters: "game/getGameFilters",
            getGameTypes: "game/getGameTypes",
            getGamesByType: "game/getGamesByType",
            updateGame: "game/updateGame",
            uploadGamePicture: "game/uploadGamePicture",
            createGame: "game/createGame",
            deleteGame: "game/deleteGame"
        }),
        updatePage(page) {
            this.page = page;
            this.initialize();
        },
        updateItemsPerPage(itemsPerPage) {
            this.itemsPerPage = itemsPerPage;
            console.log(this.itemsPerPage)
            this.initialize();
        },
        initialize() {
            if (this.selectedGameType) {
                console.log(this.filterIsWaiting)
                this.getGamesByType({
                    gameTypeCode: this.selectedGameType,
                    gameFilters: this.selectedFilters,
                    isCommunity: this.isCommunity,
                    filterCommunity: {
                        isValidated: this.filterIsValidatedCommunity,
                        isRefused: this.filterIsRefusedCommunity,
                        isWaiting: this.filterIsWaiting
                    },
                    pagination: {
                        page: this.page,
                        itemsPerPage: this.itemsPerPage
                    }
                });
            }
        },
    },

}