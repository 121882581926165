import {getField, updateField} from "vuex-map-fields";
import ShopService from "../../services/shop.service";
import Game_in_playService from "../../services/game_in_play.service";

export const dashboard = {
    namespaced: true,
    state: {
        lastDayPayments: [],
        lastDayPaymentsAmount: 0,
        totalLastDayPayments: 0,
        totalSuccessLastDayPayments: 0,
        loadingLastDayPayments: false,
        loadingTotalGameInPlays:false,
        totalGameInPlays : 0,
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_LAST_DAY_PAYMENTS(state, payload) {
            state.lastDayPayments = payload.lastDayPayments;
            state.totalLastDayPayments = payload.totalLastDayPayments;
            state.totalSuccessLastDayPayments = 0;
            let sum = 0;
            state.lastDayPayments.forEach(payment => {

                if (payment.amountReceived) {
                    sum += payment.amountReceived;
                    state.totalSuccessLastDayPayments++;
                }
            })
            state.lastDayPaymentsAmount = sum / 100;
        },
    },
    actions: {
        async getLastDayPayments({dispatch, commit, state}) {
            return new Promise((resolve, reject) => {
                state.loadingLastDayPayments = true;
                ShopService.getPaymentForLastDay().then(response => {
                    let payload = {
                        lastDayPayments: response.data['hydra:member'],
                        totalLastDayPayments: response.data['hydra:totalItems']
                    }
                    commit('SET_LAST_DAY_PAYMENTS', payload)
                    return resolve();
                }).catch(error => {
                    console.error(error);
                    dispatch("message/showErrorMessage", "Erreur de chargement des paimenents reçu hier", {root: true})
                    reject(error);
                }).finally(() => {
                    state.loadingLastDayPayments = false;
                });
            })
        },
        async getTotalGameInPlays({dispatch, state}) {
            return new Promise((resolve, reject) => {
                state.loadingTotalGameInPlays = true;
                Game_in_playService.getGamesInPlay().then(response => {
                    state.totalGameInPlays =response.data['hydra:totalItems'];
                    return resolve();
                }).catch(error => {
                    console.error(error);
                    dispatch("message/showErrorMessage", "Erreur de chargement des jeux en cours", {root: true})
                    reject(error);
                }).finally(() => {
                    state.loadingTotalGameInPlays = false;
                });
            })
        },
    }
}