var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('GameTypesSelect',{attrs:{"item-value":"code"},model:{value:(_vm.selectedGameType),callback:function ($$v) {_vm.selectedGameType=$$v},expression:"selectedGameType"}})],1),_vm._l((_vm.filteredCategories),function(gameCategory,n){return _c('v-col',{key:gameCategory.id},[_c('v-select',{attrs:{"dark":"","items":gameCategory.gameCategoryElements,"item-value":"id","deletable-chips":"","clearable":"","chips":"","label":gameCategory.name,"item-text":"name","validate-on-blur":"","multiple":""},on:{"blur":_vm.updateValue},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var disabled = ref.disabled;
return [_c('v-chip',{staticClass:"pg-color-dark-moderate-red",attrs:{"dark":"","disabled":disabled,"close":""},on:{"click:close":function($event){return _vm.removeFilter(n,item.id)}}},[_c('pg-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(item.icon))]),(!_vm.$vuetify.breakpoint.mobile)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e()],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-checkbox',{model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}}),_c('pg-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(_vm.selectedFilters[n]),callback:function ($$v) {_vm.$set(_vm.selectedFilters, n, $$v)},expression:"selectedFilters[n]"}})],1)}),(_vm.isCommunity)?_c('v-col',[_c('v-checkbox',{attrs:{"label":"Validé","value":_vm.filterIsValidatedCommunity},on:{"click":_vm.setValidatedFilter}}),_c('v-checkbox',{attrs:{"label":"Refusé","value":_vm.filterIsRefusedCommunity},on:{"click":_vm.setRefusedFilter}}),_c('v-checkbox',{attrs:{"label":"En attente","value":_vm.filterIsWaiting},on:{"click":_vm.setWaitingFilter}})],1):_vm._e(),(_vm.selectedFilters.length)?_c('v-col',{staticClass:"text-center ma-0 pa-0"},[_c('v-btn',{staticClass:"pg-color-maroon",attrs:{"dark":""},on:{"click":_vm.reset}},[_vm._v("Reset")])],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }