import AdminsManager from "../views/admins/AdminsManager";

export default {
    path: '/admins',
    name: 'Admins',
    component: () => import('../layouts/Base'),
    children: [
        {
            path: "/admins/manager",
            name: "AdminsManager",
            component: AdminsManager,
        }
    ]
};
