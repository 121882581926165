<template>
  <v-card min-width="100%">
    <v-card-title>Éléments de la catégorie</v-card-title>
    <v-card-text>
      <v-expansion-panels popout dark v-if="avatarParentElements.length">
        <v-expansion-panel
            v-for="(avatarElement,i) in avatarParentElements" :key="i"
        >
          <v-expansion-panel-header>
            <v-card v-if="avatarElement.img">
              <v-img contain width="128px" max-width="128px" :src="getImagePath(avatarElement.img.contentUrl)"
                     class="white" @click="clickUpdateAvatarElement(avatarElement)">
                <v-row justify="center" align="center" class="white--text fill-height avatar-hydra-show">{{avatarElement['@id']}}</v-row>
              </v-img>
              <v-card-actions class="pa-0">
                <v-btn @click="addChildElementToElement(avatarElement)" color="primary" width="128px">
                  <pg-icon>i-add-admin</pg-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-img src="~@/assets/sorry.jpg" v-else @click="clickUpdateAvatarElement(avatarElement)"/>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="white">
              <v-row no-gutters>
                <v-col cols="2" v-for="(avatarElementChild,j) in avatarElement.childAvatarElements" :key="j">
                  <v-card width="128px">
                    <v-img contain width="128px" max-width="128px"
                           :src="getImagePath(avatarElementChild.img.contentUrl)"
                           v-if="avatarElementChild.img && avatarElementChild.img.contentUrl" class="white"
                           @click="clickUpdateAvatarElement(avatarElementChild)">
                      <v-row justify="center" align="center" class="white--text fill-height avatar-hydra-show">{{avatarElementChild['@id']}}</v-row>
                    </v-img>
                    <v-img src="~@/assets/sorry.jpg" v-else @click="clickUpdateAvatarElement(avatarElementChild)"/>
                  </v-card>
                </v-col>
              </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row class="mt-5" no-gutters>
        <v-col cols="1" v-for="(avatarElement,j) in avatarElements" :key="j">
          <v-card v-if="avatarElement.img" width="128px" class="mr-1">
            <v-img contain width="128px" max-width="128px" :src="getImagePath(avatarElement.img.contentUrl)"
                   v-if="avatarElement.img" class="white" @click="clickUpdateAvatarElement(avatarElement)">
              <v-row justify="center" align="center" class="white--text fill-height avatar-hydra-show">{{avatarElement['@id']}}</v-row>
              </v-img>
            <v-card-actions class="pa-0">
              <v-btn @click="addChildElementToElement(avatarElement)" color="primary" width="128px">
                <pg-icon>i-add-admin</pg-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-img src="~@/assets/sorry.jpg" v-else @click="clickUpdateAvatarElement(avatarElement)"/>
        </v-col>
      </v-row>
      <v-row v-if="!hasFirstElements">Aucun élément pour cette catégorie.</v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="addElementToCategory(avatarElementCategory['@id'])">Ajouter un élément</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AvatarMixin from "../../../mixins/AvatarMixin";
import {mapActions} from "vuex";
import PgIcon from "../../PGIcon";

export default {
  name: "AvatarElements",
  components: {PgIcon},
  props: ['index'],
  mixins: [AvatarMixin],
  computed: {
    avatarElementCategory: function () {
      return this.avatarElementCategories[this.index];
    },
    avatarElements: function () {
      return this.avatarElementCategory.avatarElements.filter(element => element.firstLevel && !element.childAvatarElements.length);
    },
    avatarParentElements: function () {
      return this.avatarElementCategory.avatarElements.filter(element => element.firstLevel && element.childAvatarElements.length);
    },
    hasFirstElements: function () {
      return (this.avatarElements.length + this.avatarParentElements.length) > 0;
    }
  },
  methods: {
    ...mapActions({
      addElementToCategory: 'avatar/addElementToCategory',
      clickUpdateAvatarElement: 'avatar/clickUpdateAvatarElement',
      addChildElementToElement: 'avatar/addChildElementToElement'
    }),
  }
}
</script>

<style scoped>
.avatar-hydra-show{
  font-size: 9px !important;
  font-weight: 800;
  text-shadow: 1px 1px 2px #080607;
}
.avatar-hydra-show:hover{
  font-size: 18px !important;
  font-weight: 800;
  transform: translate(-60px, 10px);
  box-shadow: black;

}
</style>