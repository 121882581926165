import {createHelpers} from 'vuex-map-fields';
import {mapActions} from "vuex";

const {mapFields} = createHelpers({
    getterType: 'productCategory/getField',
    mutationType: 'productCategory/updateField'
});

export default {
    computed: {
        ...mapFields(['dialog', 'loading', 'editedItem', 'defaultItem', 'productCategories',
            'totalProductCategories', 'editedIndex', 'dialogDelete',
            'page', 'itemsPerPage']),
    },
    methods: {

        ...mapActions({
            getProductCategories: "productCategory/getProductCategories",
            deleteProductCategory: "productCategory/deleteProductCategory",
            createProductCategory: "productCategory/createProductCategory",
            updateProductCategory: "productCategory/updateProductCategory"

        }),
        updatePage(page) {
            this.page = page;
            this.initialize();
        },
        updateItemsPerPage(itemsPerPage) {
            this.itemsPerPage = itemsPerPage;
            console.log(this.itemsPerPage)
            this.initialize();
        },
        initialize() {
            this.getProductCategories({
                pagination: {
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                }
            });
        },
    },

}