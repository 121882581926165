<template>
  <v-card dark>
    <v-card-title>
      Catégories de produit
      <v-divider/>
      <product-category-form/>
    </v-card-title>
<!--    <v-card-title class="blue">-->
<!--      <v-text-field-->
<!--          v-model="search"-->
<!--          append-icon="mdi-magnify"-->
<!--          label="Search"-->
<!--          single-line-->
<!--          hide-details-->
<!--      ></v-text-field>-->
<!--    </v-card-title>-->
    <v-data-table
        :headers="headers"
        :items="productCategories"
        :search="search"
        :loading="loading"
        :server-items-length="totalProductCategories"
        sort-by="id"
        sort-desc
        class="elevation-15"
        :page="page"
        :items-per-page="itemsPerPage"
        @update:page="updatePage"
        @update:items-per-page="updateItemsPerPage"
    >
      <!-- template des headers !-->
      <template v-slot:top>
      </template>
      <!-- template des descriptions courtes !-->
      <template v-slot:item.description="{ item }">
        <span style="font-size: 0.7em;" v-html="item.description"></span>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ getDate(item.createdAt) }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ getDate(item.updatedAt) }}
      </template>
      <!-- template des boutons de gestion crud !-->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)" color="red">mdi-delete</v-icon>
      </template>
      <!-- template pas de données!-->
      <template v-slot:no-data>
        Aucune catégorie de produit trouvée.
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";
import ProductCategoriesMixin from "../../../mixins/shop/ProductCategoriesMixin";
import ProductCategoryForm from "./ProductCategoryForm";

export default {
  name: "ProductCategoriesDataTable",
  mixins: [ProductCategoriesMixin],
  components: {ProductCategoryForm},
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'id',
          align: 'start',
          filterable: false,
          value: 'id',
        },
        {text: 'Nom', value: 'name', sortable: true},
        {text: 'Description', value: 'description'},
        {text: 'Créé le', value: 'createdAt'},
        {text: 'Mis à jour le', value: 'updatedAt'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    getDate(dateFromDb) {
      return dateFromDb ? moment(dateFromDb).format("D-M-Y") : "NC";
    },
    editItem(item) {
      this.dialog = true;
      this.$nextTick(() => {
        this.editedIndex = this.productCategories.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.$nextTick(() => {
        this.editedIndex = this.productCategories.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
  },
  created() {
    this.initialize();
  },
  watch: {}
}
</script>

<style scoped>

</style>