<template>
  <v-container fluid>
    <v-row>
      <v-spacer/>
      <v-col cols="6" lg="3" class="text-right">
      </v-col>
    </v-row>
    <orders-data-table/>
  </v-container>
</template>

<script>
import OrdersDataTable from "../../components/sales/order/OrdersDataTable";
export default {
  name: "Orders",
  components: {OrdersDataTable}
}
</script>

<style scoped>

</style>