import {createHelpers} from 'vuex-map-fields';
import {mapActions} from "vuex";

const entity = "player";

const {mapFields} = createHelpers({
    getterType: entity + '/getField',
    mutationType: entity + '/updateField'
});

export default {
    computed: {
        ...mapFields(['dialog', 'loading', 'editedItem', 'defaultItem', 'items',
            'totalItems', 'editedIndex', 'dialogDelete',
            'page', 'itemsPerPage']),
    },
    methods: {

        ...mapActions({
            gets: entity+"/getItems",
            delete: entity+"/deleteItem",
            create: entity+"/createItem",
            update: entity+"/updateItem"

        }),
        updatePage(page) {
            this.page = page;
            this.initialize();
        },
        updateItemsPerPage(itemsPerPage) {
            this.itemsPerPage = itemsPerPage;
            console.log(this.itemsPerPage)
            this.initialize();
        },
        initialize() {
            this.gets({
                pagination: {
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                }
            });
        },
    },

}