<template>
  <v-card dark>
    <v-card-title>
      Produits
      <v-divider/>
      <product-form/>
    </v-card-title>
<!--    <v-card-title class="blue">-->
<!--      <product-categories-select/>-->
<!--      <v-text-field-->
<!--          v-model="search"-->
<!--          append-icon="mdi-magnify"-->
<!--          label="Search"-->
<!--          single-line-->
<!--          hide-details-->
<!--      ></v-text-field>-->
<!--    </v-card-title>-->
    <v-data-table
        :headers="headers"
        :items="products"
        :search="search"
        :loading="loading"
        :server-items-length="totalProducts"
        sort-by="id"
        sort-desc
        class="elevation-15"
        :page="page"
        :items-per-page="itemsPerPage"
        @update:page="updatePage"
        @update:items-per-page="updateItemsPerPage"
    >
      <!-- template des headers !-->
      <template v-slot:top>
      </template>
      <!-- template des descriptions courtes !-->
      <template v-slot:item.description="{ item }">
        <span style="font-size: 0.7em;" v-html="item.description"></span>
      </template>
      <template v-slot:item.price="{ item }">
        <span v-html="item.price"></span> €
        <span v-if="item.awardPointsCost">/{{item.awardPointsCost}}<pg-icon s-m inline>i-pimentos</pg-icon></span>
      </template>
      <template v-slot:item.category="{ item }">
        <span v-html="item.category.name" class="mr-1"></span>
        <span v-if="item.awardPoints">({{item.awardPoints}}<pg-icon s-m inline>i-pimentos</pg-icon>)</span>
        <span v-if="item.gameCreditsCoins">({{item.gameCreditsCoins}}<pg-icon s-m inline>i-game-credits-coin</pg-icon>)</span>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ getDate(item.createdAt) }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ getDate(item.updatedAt) }}
      </template>
      <!-- template des boutons de gestion crud !-->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)" color="red">mdi-delete</v-icon>
      </template>
      <!-- template pas de données!-->
      <template v-slot:no-data>
        Aucun produit trouvé.
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";
import ProductMixin from "../../../mixins/shop/ProductMixin";
import ProductForm from "./ProductForm";
import PgIcon from "../../PGIcon";

export default {
  name: "ProductsDataTable",
  mixins: [ProductMixin],
  components: {PgIcon, ProductForm},
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'id',
          align: 'start',
          filterable: false,
          value: 'id',
        },
        {text: 'Nom', value: 'name', sortable: true},
        {text: 'Description', value: 'description'},
        {text: 'Tarif', value: 'price', sortable: true},
        {text: 'Catégorie', value: 'category'},
        {text: 'Créé le', value: 'createdAt'},
        {text: 'Mis à jour le', value: 'updatedAt'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    getDate(dateFromDb) {
      return dateFromDb ? moment(dateFromDb).format("D-M-Y") : "NC";
    },
    editItem(item) {
      this.dialog = true;
      this.$nextTick(() => {
        this.editedIndex = this.products.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.$nextTick(() => {
        this.editedIndex = this.products.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
  },
  created() {
    this.initialize();
  },
  watch: {}
}
</script>

<style scoped>

</style>