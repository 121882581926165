<template>
  <v-app>
    <notification/>
    <v-snackbar
        v-model="showSnackBar"
        :color="snackBarColor"
        dark
        app
        width="100vw"
    >
      {{ snackBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            text
            @click="showSnackBar = false"
            v-bind="attrs"
        >
          <v-icon>mdi-close</v-icon>
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer app  dark fixed clipped overlay-color="red" style="overflow-y: hidden!important;"
                         v-model="showNavigation">
      <Menu/>
    </v-navigation-drawer>
    <v-app-bar class="pg-color-white" clipped-left dense :hide-on-scroll="$vuetify.breakpoint.smAndDown"
               app dark>
      <v-row no-gutters align="center" @click="$router.push({name:'Home'})">
        PRIVATE
        <v-img
            alt="Vuetify Name"
            class="shrink mt-1 ml-1 mr-1"
            contain
            min-width="10"
            src="~@/assets/logo-transparent.png"
            width="28"
            @click="$router.push({name:'Home'})"
        />
        GAMES
      </v-row>
      <v-spacer></v-spacer>
      <mercure-status/>
      <v-btn icon small @click="showNotifications=!showNotifications">
        <v-badge
            color="red"
            :content="nbNotificationToRead"
            overlap
            :dot="nbNotificationToRead<=0"
        >
          <pg-icon mono>i-notif</pg-icon>
        </v-badge>
      </v-btn>
      <v-btn icon @click="logout">
        <pg-icon MD class="ma-0">i-logout-admin</pg-icon>
      </v-btn>
      <v-btn icon @click="showNavigation=!showNavigation" v-show="$vuetify.breakpoint.smAndDown">
        <pg-icon>i-burger-white</pg-icon>
      </v-btn>
    </v-app-bar>
    <v-main fill-height class="mb-15 dark-gray" style="background-color: #454040">
      <v-row no-gutters align-content="center" justify="center">
          <v-container :fluid="$vuetify.breakpoint.mdAndUp" class="pa-0" style="max-width: 1446px">
            <router-view/>
          </v-container>
      </v-row>
    </v-main>
    <footer-menu/>
  </v-app>
</template>

<script>

import MessageMixin from "@/mixins/MessageMixin";
import PgIcon from "@/components/PGIcon";
import Menu from "@/components/Menu";
import NavigationMixin from "@/mixins/NavigationMixin";
import FooterMenu from "@/components/FooterMenu";
import Notification from "@/components/Notification";
import NotificationMixin from "@/mixins/NotificationMixin";
import AuthAdminNeeded from "../mixins/AuthAdminNeeded";
import MercureStatus from "../components/MercureStatus";



export default {
  name: "BaseLayout",
  components: {MercureStatus, Notification, FooterMenu, Menu, PgIcon},
  mixins:[MessageMixin,NavigationMixin,NotificationMixin, AuthAdminNeeded],
  data: () => ({
    drawer: true,
    scrolled: false,
  }),
  created() {
  },
}
</script>

<style scoped>

</style>