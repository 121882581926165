<template>
  <v-dialog v-model="dialogPlayerInteractionFrom" @click:outside="onClose" max-width="500px">
    <v-card dark v-if="editedPlayerInteractionFrom">
      <v-card-title>Éditeur d'intéraction :
        <pg-icon>i-profil</pg-icon>
        {{ editedPlayerInteractionFrom.playerNumberFrom }} >
        {{
          editedPlayerInteractionFrom.playerNumberDestination ? editedPlayerInteractionFrom.playerNumberDestination : '?'
        }}
        <pg-icon mono>i-profil</pg-icon>
      </v-card-title>
      <v-card-text>
        <v-select v-model="editedPlayerInteractionFrom.playerInteractionType"
                  :items="gameStagePlayerInteractionTypes" item-value="@id" item-text="name" label="Type d'intéraction"/>
        <v-text-field v-model="editedPlayerInteractionFrom.playerNumberFrom" label="Numéro de joueur d'origine"
                      disabled/>
        <v-select v-model="editedPlayerInteractionFrom.playerNumberDestination" :items="otherPlayerNumbers"
                  label="Numéro de joueur de destination"/>
        <v-text-field v-model="editedPlayerInteractionFrom.labelFrom" label="Label de la zone d'intéraction en envoie"/>
        <v-text-field v-model="editedPlayerInteractionFrom.labelDestination"
                      label="Label de la zone d'intéraction en réception"/>
        <v-select v-model="editedPlayerInteractionFrom.gameStageFrom" :items="stages" item-value="@id" disabled
                  item-text="stageNumber" label="Niveau d'origine"/>
        <v-select v-model="editedPlayerInteractionFrom.gameStageDestination"
                  :items="onlySavedStages.filter(stage => stage['@id'] != editedPlayerInteractionFrom.gameStageFrom)"
                  item-value="@id"
                  item-text="stageNumber" label="Niveau de destination"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="cyan" @click="close">Fermer</v-btn>
        <v-btn color="error" @click="onDelete">Supprimer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GameStagesMixin from "../../../mixins/GameStagesMixin";
import PgIcon from "../../PGIcon";
import GameMixin from "../../../mixins/GameMixin";

export default {
  name: "GameStagesPlayerInteractionEditor",
  components: {PgIcon},
  mixins: [GameStagesMixin, GameMixin],
  computed: {
    otherPlayerNumbers: function () {
      let playerNumbers = [];
      let i;
      for (i = 1; i <= this.nbPlayers; i++) {
        if (i != this.editedPlayerInteractionFrom.playerNumberFrom) {
          playerNumbers.push(i);
        }
      }
      return playerNumbers;
    }
  },
  created() {
    this.getGameStagePlayerInteractionTypes();
  },
  methods: {
    close() {
      this.dialogPlayerInteractionFrom = false;
      this.onClose();
    },
    onClose() {
      this.editedPlayerInteractionFrom = null;
    },
    onDelete() {
      this.deletePlayerInteractionFrom();
      this.close();
    }
  }
}
</script>

<style scoped>

</style>