<template>
  <div>
    <p-w-a-manager/>
    <refresh-manager/>
    <router-view/>
  </div>
</template>

<script>
import AuthAdminNeeded from "./mixins/AuthAdminNeeded";
import PWAManager from "./components/PWAManager";
import RefreshManager from "./components/RefreshManager";

export default {
  name: 'App',
  mixins: [AuthAdminNeeded],
  components: {
    RefreshManager,
    PWAManager
  },

  data: () => ({
    //
  }),
};
</script>
