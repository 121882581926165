import Vue from "vue";
import Vuex from "vuex";

import {auth} from './modules/auth.module.js';
import {message} from "@/store/modules/message.module";
import {navigation} from "@/store/modules/navigation.module";
import {pwa} from "@/store/modules/pwa.module";
import {game} from "./modules/content/game.module";
import {gameContest} from "./modules/content/game-contest.module"
import {avatar} from "./modules/content/avatar.module";
import {gameStages} from "./modules/content/game-stages.module";
import {notification} from "./modules/notification.module";
import {mercure} from "./modules/mercure.module";
import {productCategory} from "./modules/shop/product_category.module"
import {product} from "./modules/shop/product.module"
import {order} from "./modules/sales/order.module";
import {payment} from "./modules/sales/payment.module";
import {player} from "./modules/players/player.module";
import {admin} from "./modules/admins/admin.module";
import {dashboard} from "./modules/dashboard.module";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

var ls = new SecureLS({isCompression: false});

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        message,
        navigation,
        pwa,
        player,
        admin,
        notification,
        game,
        gameContest,
        avatar,
        gameStages,
        mercure,
        productCategory,
        product,
        order,
        payment,
        dashboard
    },
    plugins: [createPersistedState({
        key: 'pg-client-vuex',
        storage: {
            getItem: (key) => {
                ls.get(key);
            },
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        },
        reducer(val) {
            if (!val.auth.token) {
                return {};
            } else {
                return val;
            }
        }
    })]
});
