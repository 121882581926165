<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="1000px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark
               class="mb-2"
               v-bind="attrs"
               v-on="on">
          <v-icon>mdi-plus-circle</v-icon>
          Ajouter une catégorie
        </v-btn>
      </template>
      <v-card dark v-if="dialog">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                v-if="dialog"
            >
              <v-row>
                <v-col cols="12" class="pa-0">
                  <v-text-field
                      v-model="editedItem.name"
                      label="Titre"
                      :rules="nameRules"
                      :counter="255"
                      required
                  />
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-simple-checkbox v-model="editedItem.isAwardPointsProduct"></v-simple-checkbox>
                    Produits de type pimentos
                    <pg-icon inline>i-pimentos</pg-icon>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-simple-checkbox v-model="editedItem.isGameCreditsCoinsProduct"></v-simple-checkbox>
                    Produits de type partie supplémentaire
                    <pg-icon inline>i-game-credits-coin</pg-icon>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <label class="label">Description courte</label>
                  <quill-editor class="pg-color-deep-red" v-model="editedItem.description"
                                :options="editorOptions"/>

                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveProductCategory"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card dark>
        <v-card-title class="headline">Voulez vous vraiement supprimer cette catégorie ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MessageMixin from "../../../mixins/MessageMixin";
import QuillEditorMixin from "../../../mixins/QuillEditorMixin";
import ProductCategoriesMixin from "../../../mixins/shop/ProductCategoriesMixin";
import PgIcon from "../../PGIcon";

export default {
  name: "ProductCategoryForm",
  components: {PgIcon},
  mixins: [ProductCategoriesMixin, MessageMixin, QuillEditorMixin],
  data() {
    return {
      valid: true,
      nameRules: [
        v => !!v || 'Le titre est obligatoire',
        v => (v && v.length >= 5) || 'Le titre doit faire 5 lettres ou plus',
      ],
      descriptionRules: [
        v => !!v || 'La description courte est obligatoire',
        v => (v && v.length >= 20) || 'La description courte doit faire 20 lettres ou plus',
      ],
    }
  },
  methods: {
    async deleteItemConfirm() {
      if (this.editedIndex > -1) {
        await this.deleteProductCategory().then(() => {
          this.productCategories.splice(this.editedIndex, 1);
        })
      }
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async saveProductCategory(closeOnSave = true) {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          await this.updateProductCategory().then(() => {
            if (closeOnSave) {
              this.close();
            }
            this.$forceUpdate();
          }).catch(() => {
            console.log('hum ou pas')
          });
        } else {
          this.createProductCategory().then(() => {
            if (closeOnSave) {
              this.close();
            }
            this.$forceUpdate();
          })
        }
      } else {
        this.showErrorMessage("Le formulaire n'est pas valide !");
      }
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouvelle catégorie' : 'Editer catégorie'
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
}
</script>

<style scoped>

</style>