import {getField, updateField} from 'vuex-map-fields';

const lastAsked = localStorage.getItem('lastAskedPWA');

const initialState = lastAsked ? {lastAsked: lastAsked, isPWAReady: null} : {lastAsked: null, isPWAReady: null};

export const pwa = {
    namespaced: true,
    state: initialState,
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_LAST_ASKED_PWA(state, payload) {
            state.lastAsked = payload;
            localStorage.setItem('lastAskedPWA', payload);
        }
    },
    actions: {
        updateLastAskedPWA({commit}) {
            commit('SET_LAST_ASKED_PWA', Date.now());
        }
    }
}