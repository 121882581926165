var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.filteredCategories),function(gameCategory){return _c('v-col',{key:gameCategory.id,attrs:{"cols":"4"}},[_c('v-select',{attrs:{"dark":"","items":gameCategory.gameCategoryElements,"item-value":"@id","deletable-chips":"","clearable":"","chips":"","label":gameCategory.name,"item-text":"name","validate-on-blur":"","multiple":""},on:{"blur":_vm.updateValue},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var disabled = ref.disabled;
return [_c('v-chip',{staticClass:"pg-color-dark-moderate-red",attrs:{"dark":"","disabled":disabled,"close":""},on:{"click:close":function($event){return _vm.removeFilter(item['@id'])}}},[_c('pg-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(item.icon))]),_c('span',[_vm._v(_vm._s(item.name))])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-checkbox',{model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}}),_c('pg-icon',{staticClass:"mr-5"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }