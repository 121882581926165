var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"dark":""}},[_c('v-card-title',[_c('game-header'),_c('game-publisher'),_c('game-stages-editor')],1),_c('v-card-title',{staticClass:"blue"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-15",attrs:{"headers":_vm.headers,"items":_vm.games,"search":_vm.search,"loading":_vm.loading,"server-items-length":_vm.totalGames,"sort-by":"id","sort-desc":"","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.descriptionShort",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"0.7em"},domProps:{"innerHTML":_vm._s(item.descriptionShort)}})]}},{key:"item.gameDevices",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"value":item.gameDevices.length > 0,"disabled":""}})]}},{key:"item.categoryElements",fn:function(ref){
var item = ref.item;
return [_c('v-row',_vm._l((item.categoryElements),function(element,e){return _c('pg-icon',{key:e,attrs:{"s-m":""}},[_vm._v(_vm._s(element['icon'])+" ")])}),1)]}},{key:"item.gameType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getGameTypeLabel(item))+" ")]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":_vm.getImagePath(item.image),"contain":"","width":"50px"}})]}},{key:"item.publicationStart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.publicationStart))+" ")]}},{key:"item.publicationEnd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.publicationEnd))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red","disabled":item.playedCounter>0},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"cyan"},on:{"click":function($event){return _vm.publishItem(item)}}},[_vm._v("mdi-publish")]),_c('v-icon',{attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.editLevelItem(item)}}},[_vm._v("mdi-gamepad-square")])]}},{key:"no-data",fn:function(){return [_vm._v(" Aucun jeu ne correspond à vos filtres. ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }