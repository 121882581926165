<template>
  <v-row no-gutters>
    <v-chip v-if="devices && !devices.length">Aucun matériel nécessaire</v-chip>
    <v-chip-group column>
      <v-chip v-for="(device,n) in devices" v-bind:key="n" @click="editDeviceElement(device,n)" close
              v-on:click:close="deleteDevice(n)">
        {{ device.name }}
      </v-chip>
    </v-chip-group>
    <v-spacer/>
    <v-dialog
        v-model="dialogAddDevice"
        persistent
        max-width="390"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
        >
          Ajouter
        </v-btn>
      </template>
      <v-card dark>
        <v-card-title class="headline">
          {{ title }} équipement pour ce jeu
        </v-card-title>
        <v-card-text>
          <v-form ref="formDevice" lazy-validation v-model="valid">
            <v-row>
              <v-col cols="12" class="pa-0">
                <v-text-field
                    v-model="editDevice.name"
                    label="Nom"
                    :counter="255"
                    :rules="nameRules"
                    required
                />
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                    v-model="editDevice.link"
                    label="Lien"
                    :counter="255"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              @click="close"
          >
            Annuler
          </v-btn>
          <v-btn
              color="primary"
              @click="save"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteDevice" width="350px">
      <v-card dark>
        <v-card-title class="headline text-center text-no-wrap">Effacer l'équipement ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDelete">Cancel</v-btn>
          <v-btn color="error" @click="deleteDeviceConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
import GameMixin from "../../../mixins/GameMixin";
import MessageMixin from "../../../mixins/MessageMixin";

const emptyDevice = {
  link: "",
  name: "",
};
export default {
  name: "GameDeviceSelect",
  mixins: [GameMixin, MessageMixin],
  props: ['value', 'itemValue', 'items'],
  data: () => {
    return {
      devices: [],
      dialogAddDevice: false,
      dialogDeleteDevice: false,
      editDeviceIndex: -1,
      editDevice: {
        link: "",
        name: "",
      },
      valid: true,
      nameRules: [
        v => !!v || 'Le nom est obligatoire',
        v => (v && v.length >= 3) || 'Le titre doit faire 3 lettres ou plus',
      ],
    }
  },
  methods: {
    save() {
      this.$refs.formDevice.validate();
      if (this.valid) {
        let device = {...emptyDevice};
        device.link = this.editDevice.link;
        device.name = this.editDevice.name;
        if (this.editDeviceIndex >= 0) {
          this.devices[this.editDeviceIndex] = device;
        } else {
          this.devices.push(device);
        }
        this.dialogAddDevice = false;
        this.editDevice.name = "";
        this.editDevice.link = "";
        this.editDeviceIndex = -1;
      } else {
        this.showErrorMessage("Le formulaire n'est pas valide");
      }
    },
    editDeviceElement(device, index) {
      this.dialogAddDevice = true;
      this.editDeviceIndex = index;
      Object.assign(this.editDevice, device);
    },
    close() {
      this.editDevice = {...emptyDevice};
      this.editDeviceIndex = -1;
      this.dialogAddDevice = false;
    },
    closeDelete() {
      this.editDeviceIndex = -1;
      this.dialogDeleteDevice = false;
    },
    deleteDeviceConfirm() {
      this.devices.splice(this.editDeviceIndex, 1);
      this.editDeviceIndex = -1;
      this.dialogDeleteDevice = false;
    },
    deleteDevice(index) {
      this.editDeviceIndex = index;
      this.dialogDeleteDevice = true;
    }
  },
  computed: {
    title() {
      return this.editDeviceIndex < 0 ? "Ajout" : "Editer";
    }
  },
  created() {
    this.devices = this.value;
  },
  watch: {
    devices: function () {
      this.$emit('input', this.devices);
    },
    value: function () {
      this.devices = this.value;
    }
  }
}
</script>

<style scoped>

</style>