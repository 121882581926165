import APIService from "./api.service";

class AvatarService {
    async getAvatarElementCategories() {
        return APIService.get('/avatar_element_categories');
    }

    async getAvatarGenderTypes() {
        return APIService.get('/avatar_gender_types');
    }

    async getAvatarShapeTypes() {
        return APIService.get('/avatar_shape_types');
    }

    async getAvatarSkinColors() {
        return APIService.get('/avatar_skin_colors');
    }

    async getAvatarHairColors() {
        return APIService.get('/avatar_hair_colors');
    }

    async uploadAvatarPicture(data, avatarId = null) {
        let url = '/avatar_images';
        if (avatarId) {
            url = avatarId
        }
        return await APIService.post(url, data);
    }

    async getAvatarPicture(avatarImageId) {
        return APIService.get(avatarImageId);
    }

    async updateAvatarElementCategory(avatarElementCategory) {
        return await APIService.put(avatarElementCategory['@id'], avatarElementCategory);
    }

    async createAvatarElementCategory(avatarElementCategory) {
        return await APIService.post('/avatar_element_categories', avatarElementCategory)
    }


    async createAvatarElement(avatarElement) {
        return await APIService.post('/avatar_elements', avatarElement);
    }

    async updateAvatarElement(avatarElement) {
        return await APIService.put(avatarElement['@id'], avatarElement);
    }

    async deleteAvatarElement(avatarElement) {
        return await APIService.delete(avatarElement['@id']);
    }

    async deleteAvatarElementCategory(avatarElementCategory) {
        return await APIService.delete(avatarElementCategory['@id']);
    }

    async createAvatarHairColor(avatarHairColor) {
        return await APIService.post('/avatar_hair_colors', avatarHairColor)
    }

    async deleteAvatarHairColor(avatarHairColor) {
        return await APIService.delete(avatarHairColor['@id']);
    }

    async createAvatarSkinColor(avatarSkinColor) {
        return await APIService.post('/avatar_skin_colors', avatarSkinColor)
    }

    async deleteAvatarSkinColor(avatarSkinColor) {
        return await APIService.delete(avatarSkinColor['@id']);
    }

}

export default new AvatarService();