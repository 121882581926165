<template>
  <div>
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/women/69.jpg"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>Jinic-Média</v-list-item-content>
      </v-list-item>
      <v-divider/>
    </v-list>
    <v-list>
      <v-list-item-group
          v-model="selectedItem"
          color="primary"
      >
        <div v-for="(link,i) in links" :key="i">
          <v-list-group
              :value="false"
              v-if="link.subLinks"
          >
            <template v-slot:prependIcon>
              <pg-icon MD class="ma-0">{{ link.icon }}</pg-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </template>
            <v-list-item v-for="(subLink,m) in link.subLinks" v-bind:key="m" class="blue" :to="subLink.to">
              <v-list-item-content>
                <v-list-item-title v-text="subLink.text"/>
              </v-list-item-content>
              <v-list-item-icon>
                <pg-icon MD class="ma-0">{{ subLink.icon }}</pg-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :to="link.to">
            <v-list-item-icon>
              <pg-icon MD class="ma-0">{{ link.icon }}</pg-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="link.text"/>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <pg-icon MD class="ma-0">i-logout-admin</pg-icon>
          </v-list-item-icon>
          <v-list-item-content v-text="'Déconnexion'"/>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import PgIcon from "@/components/PGIcon";
import {createHelpers} from "vuex-map-fields";
import AuthAdminNeeded from "../mixins/AuthAdminNeeded";

const {mapFields} = createHelpers({
  getterType: 'player/getField',
  mutationType: 'player/updateField'
});
export default {
  name: "Menu",
  components: {PgIcon},
  mixins: [AuthAdminNeeded],
  computed: {
    ...mapFields(['firstName', 'lastName'])
  },
  data() {
    return {
      selectedItem: "",
      links: [
        {
          to: {name: 'DashBoard'},
          icon: 'i-dashboard-admin',
          text: 'Dashboard'
        },
        {
          to: '/',
          icon: 'i-content-admin',
          text: 'Contenu',
          subLinks: [
            {
              to: {name: 'DashBoard'},
              icon: 'i-games-categories-admin',
              text: 'Catégories de jeux',
            },
            {
              to: {name: 'ContentGames'},
              icon: 'i-games-admin',
              text: 'Jeux',
            },
            {
              to: {name: 'ContentGamesContest'},
              icon: 'i-weekly-game-admin',
              text: 'Jeu hebdo',
            },
            {
              to: {name: 'DashBoard'},
              icon: 'i-badges-admin',
              text: 'Badges et trophées',
            },
            {
              to: {name: 'ContentAvatars'},
              icon: 'i-avatar-admin',
              text: 'Avatars',
            },
          ]
        },
        {
          to: '/',
          icon: 'i-marketing-admin',
          text: 'Marketing',
          subLinks: [
            {
              to: {name: 'DashBoard'},
              icon: 'i-marketing-calendar-admin',
              text: 'Calendriers marketing',
            },
            {
              to: {name: 'DashBoard'},
              icon: 'i-analytical-data-admin',
              text: 'Données analytiques',
            },
            {
              to: {name: 'DashBoard'},
              icon: 'i-analytical-content-admin',
              text: 'Analyse de contenu',
            },
          ]
        },
        {
          to: '/',
          icon: 'i-shop-admin',
          text: 'Boutique',
          subLinks: [
            {
              to: {name: 'ProductCategories'},
              icon: 'i-taxons-admin',
              text: 'Taxons',
            },
            {
              to: {name: 'Products'},
              icon: 'i-products-admin',
              text: 'Produits',
            },
            {
              to: {name: 'DashBoard'},
              icon: 'i-attributes-admin',
              text: 'Attributs',
            }, {
              to: {name: 'DashBoard'},
              icon: 'i-options-admin',
              text: 'Options',
            },
          ]
        },
        {
          to: '/',
          icon: 'i-sales-admin',
          text: 'Ventes',
          subLinks: [
            {
              to: {name: 'Orders'},
              icon: 'i-orders-admin',
              text: 'Commandes',
            },
            {
              to: {name: 'Payments'},
              icon: 'i-payments-admin',
              text: 'Paiements',
            },
            {
              to: {name: 'DashBoard'},
              icon: 'i-groups-admin',
              text: 'Groupes',
            },]
        },
        {
          to: '/',
          icon: 'i-community-admin',
          text: 'Communauté',
          subLinks: [
            {
              to: {name: 'CommunityGames'},
              icon: 'i-community-content-admin',
              text: 'Contenu communauté',
            },]
        },
        {
          to: '/',
          icon: 'i-members-admin',
          text: 'Membres',
          subLinks: [
            {
              to: {name: 'PlayersManager'},
              icon: 'i-community-admin',
              text: 'Gestionnaire utilisateurs',
            },]
        },
        {
          to: '/',
          icon: 'i-mailbox-admin',
          text: 'Boite de réception "PG"',
          subLinks: [
            {
              to: {name: 'DashBoard'},
              icon: 'i-mail-admin',
              text: 'Mail box',
            }, {
              to: {name: 'DashBoard'},
              icon: 'i-support-ticket-admin',
              text: 'Support ticket',
            },
            {
              to: {name: 'DashBoard'},
              icon: 'i-trello-admin',
              text: 'Trello'
            },]
        },
        {
          to: '/',
          icon: 'i-administrator-admin',
          text: 'Administration', subLinks: [
            {
              to: {name: 'AdminsManager'},
              icon: 'i-user-management-admin',
              text: 'Gestion des comptes',
            }
          ]
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>