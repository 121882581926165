import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                primary: '#6ec4ac',
                secondary: '#95d2b7',
                tertiary: '#495057',
                accent: '#82B1FF',
                error: '#f55a4e',
                info: '#00d3ee',
                success: '#5cb860',
                warning: '#ffa21a',
                general: '#2196F3'
            },
            light: {
                primary: '#6ec4ac',
                secondary: '#95d2b7',
                tertiary: '#495057',
                accent: '#82B1FF',
                error: '#f55a4e',
                info: '#00d3ee',
                success: '#5cb860',
                warning: '#ffa21a',
                general: '#2196F3'
            }
        }
    }
});
