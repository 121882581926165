<template>
  <v-card dark>
    <v-card-title>
      Commandes
      <v-divider/>
      <order-form/>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :server-items-length="totalProducts"
        sort-by="id"
        sort-desc
        class="elevation-15"
        :page="page"
        :items-per-page="itemsPerPage"
        @update:page="updatePage"
        @update:items-per-page="updateItemsPerPage"
    >
      <!-- template des headers !-->
      <template v-slot:top>
      </template>
      <!-- template des descriptions courtes !-->
      <template v-slot:item.payment="{ item }">
        <v-btn @click="console.log(item)">{{item.payment}}</v-btn>
      </template>
      <template v-slot:item.total="{ item }">
        <span v-html="item.total"></span> €
      </template>
      <template v-slot:item.orderItems="{ item }">
        <v-btn @click="console.log(item)">Produits</v-btn>
      </template>
      <template v-slot:item.player="{ item }">
        <v-btn @click="console.log(item)">{{item.player}}</v-btn>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ getDate(item.createdAt) }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ getDate(item.updatedAt) }}
      </template>
      <!-- template des boutons de gestion crud !-->
<!--      <template v-slot:item.actions="{ item }">-->
<!--        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>-->
<!--        <v-icon small class="mr-2" @click="deleteItem(item)" color="red">mdi-delete</v-icon>-->
<!--      </template>-->
      <!-- template pas de données!-->
      <template v-slot:no-data>
        Aucune commande trouvée.
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";
import OrderForm from "./OrderForm";
import OrderMixin from "../../../mixins/sales/OrderMixin";

export default {
  name: "OrdersDataTable",
  mixins: [OrderMixin],
  components: {OrderForm},
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'id',
          align: 'start',
          filterable: false,
          value: 'id',
        },
        {text: 'Joueur', value: 'player', sortable: true},
        {text: 'Total', value: 'total'},
        {text: 'payment', value: 'payment'},
        {text: 'status', value: 'status'},
        {text: 'Créé le', value: 'createdAt'},
        {text: 'Mis à jour le', value: 'updatedAt'},
        {text: 'Produits', value: 'orderItems', sortable: false},
        {text: 'awardPointsOperations', value: 'awardPointsOperations', sortable: false},
        // {text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    getDate(dateFromDb) {
      return dateFromDb ? moment(dateFromDb).format("D-M-Y") : "NC";
    },
    editItem(item) {
      this.dialog = true;
      this.$nextTick(() => {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.$nextTick(() => {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
  },
  created() {
    this.initialize();
  },
  watch: {}
}
</script>

<style scoped>

</style>