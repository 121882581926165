var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"dark":""}},[_c('v-card-title',[_vm._v(" Jeux hebdo "),_c('v-divider'),_c('game-contest-form'),_c('game-contest-winner-selection-dialog')],1),_c('v-data-table',{staticClass:"elevation-15",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"server-items-length":_vm.totalItems,"sort-by":"id","sort-desc":"","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.endDate))+" ")]}},{key:"item.gameContestType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getGameContestLabel(item.gameContestType))+" ")]}},{key:"item.nbParticipations",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nbParticipations)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]),(!item.isOver)?_c('v-btn',{on:{"click":function($event){_vm.gameContestWinnerSelected = item}}},[_vm._v("Participations")]):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" Aucun jeu concours trouvé. ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }