import APIService from "./api.service";

class ShopService {

    async getProducts(pagination = {}) {
        let url = '/products?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 50;
        url += 'page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage
        }
        return await APIService.get(url);
    }

    async updateProduct(product) {
        return await APIService.put(product['@id'], product);
    }

    async createProduct(product) {
        return await APIService.post('/products', product);
    }

    async deleteProduct(product) {
        return await APIService.delete(product['@id']);
    }

    // Product Category
    async getProductCategories(pagination = {}) {
        let url = '/product_categories?';
        let page = pagination.page != undefined ? pagination.page : 1;
        let itemsPerPage = pagination.itemsPerPage != undefined ? pagination.itemsPerPage : 10;
        url += 'page=' + page;
        if (itemsPerPage > 0) {
            url += '&itemsPerPage=' + itemsPerPage
        }
        return await APIService.get(url);
    }

    async updateProductCategory(productCategory) {
        return await APIService.put(productCategory['@id'], productCategory);
    }

    async createProductCategory(productCategory) {
        return await APIService.post('/product_categories', productCategory);
    }

    async deleteProductCategory(productCategory) {
        return await APIService.delete(productCategory['@id']);
    }

    async getPaymentForLastDay() {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        let yearY = yesterday.getFullYear();
        let monthY = yesterday.getMonth();
        let dayY = yesterday.getDate();
        let year = today.getFullYear();
        let month = today.getMonth();
        let day = today.getDate();
        let url = '/payments?pagination=false';
        url += '&updatedAt%5Bstrictly_before%5D=' + year + '%2F' + (month+1) + '%2F' + day;
        url += '&updatedAt%5Bafter%5D=' + yearY + '%2F' + (monthY+1) + '%2F' + dayY;
        return await APIService.get(url);
    }
}

export default new ShopService();