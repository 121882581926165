<template>
  <v-select :items="productCategories"
            :item-value="itemValue"
            item-text="name"
            v-model="selected"
            dark
            chips
            label="Catégorie de produit"
            :return-object="!itemValue"
  />
</template>

<script>
import ProductCategoriesMixin from "../../../mixins/shop/ProductCategoriesMixin";

export default {
  name: "ProductCategoriesSelect",
  mixins: [ProductCategoriesMixin],
  props: ['value', 'itemValue'],
  data: () => {
    return {
      selected: null,
    }
  },
  watch: {
    selected: function () {
      this.$emit('input', this.selected);
    },
    value: function () {
      this.selected = this.value;
    }
  },
  created() {
    console.log(this.productCategories)
    if(!this.productCategories || !this.productCategories.length){
      this.getProductCategories();
    }
  }
}
</script>
<style scoped>

</style>