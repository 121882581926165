<template>
  <v-dialog v-model="avatarElementEdition" max-width="1000px">
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" v-if="avatarElement.parentAvatarElement && avatarElement.parentAvatarElement.img" class="black">
            <v-img contain width="128px" max-width="128px" :src="getImagePath(avatarElement.parentAvatarElement.img.contentUrl)" class="white"/>
          </v-col>
          <v-col col="4" v-if="avatarElement['@id']">
            <image-uploader v-model="avatarElement.img" :upload-function="uploadAvatarPicture"
                            :image-owner="{type:'avatar_elements','id':avatarElement['@id']}"/>
          </v-col>
<!--          <v-col cols="6">-->
<!--            <label class="label">Couleur principale</label>-->
<!--            <v-color-picker mode="hexa" hide-mode-switch v-model="avatarElement.color"/>-->
<!--          </v-col>-->
          <v-col cols="8">
            <avatar-gender-type-select v-model="avatarElement.genderType"/>
            <avatar-shape-type-select v-model="avatarElement.shapeType"/>
            <avatar-skin-color-select v-model="avatarElement.skinColor"/>
            <avatar-air-color-select v-model="avatarElement.hairColor"/>
          </v-col>
          <v-col cols="12">
            <v-row no-gutters>
              <v-checkbox
                  v-model="avatarElement.isPremium"
                  label="premium"
              ></v-checkbox>
              <v-checkbox
                  v-model="avatarElement.hasToBeOwned"
                  label="doit être acheté"
              ></v-checkbox>
            </v-row>

          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-btn @click="save">Sauver</v-btn>
        <v-btn @click="clearAvatarElementForm">Fermer</v-btn>
        <v-divider v-if="avatarElement['@id']"/>
        <v-btn class="red" dark v-if="avatarElement['@id']" @click="deleteAvatarElement">Supprimer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AvatarMixin from "../../../mixins/AvatarMixin";
import AvatarShapeTypeSelect from "./AvatarShapeTypeSelect";
import AvatarGenderTypeSelect from "./AvatarGenderTypeSelect";
import AvatarSkinColorSelect from "./AvatarSkinColorSelect";
import AvatarAirColorSelect from "./AvatarHairColorSelect";
import {mapActions} from "vuex";
import ImageUploader from "../../ImageUploader";

export default {
  name: "AvatarElementForm",
  components: {
    ImageUploader,
    AvatarAirColorSelect,
    AvatarSkinColorSelect,
    AvatarGenderTypeSelect,
    AvatarShapeTypeSelect
  },
  mixins: [AvatarMixin],
  methods: {
    ...mapActions({
      clearAvatarElementForm: 'avatar/clearAvatarElementForm',
      createAvatarElement: 'avatar/createAvatarElement',
      uploadAvatarPicture: 'avatar/uploadAvatarPicture',
      updateAvatarElement: 'avatar/updateAvatarElement',
      deleteAvatarElement : 'avatar/deleteAvatarElement'
    }),
    save() {
      if (!this.avatarElement['@id']) {
        this.createAvatarElement();
      } else {
        console.log('update')
        this.updateAvatarElement();
      }
    }
  },
  watch: {
    avatarElementEdition: function () {
      if (!this.avatarElementEdition) this.clearAvatarElementForm();
    }
  },
  destroyed() {
    this.clearAvatarElementForm();
  }
}
</script>

<style scoped>

</style>