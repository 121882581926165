import {getField, updateField} from 'vuex-map-fields';
import ApiService from "../../../services/api.service";

const entity = 'game_contests';

export const gameContest = {
    namespaced: true,
    state: {
        items: [],
        totalItems: 0,
        loading: false,
        dialog: false,
        dialogDelete: false,
        gameContestWinnerSelected: null,
        gameContestParticipations: [],
        gameContestParticipationsServerCount: 0,
        editedItem: {
            id: null,
            startDate: null,
            endDate: null,
            title: null,
            description: null,
            gameContestType: 0,
            image: null

        },
        editedIndex: -1,
        defaultItem: {
            id: null,
            startDate: null,
            endDate: null,
            title: null,
            description: null,
            gameContestType: 0,
            image: null
        },
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_ITEMS(state, payload) {
            state.items = payload.items;
            state.totalItems = payload.totalItems;
        },
        SET_PARTICIPATIONS_ITEMS(state, payload) {
            console.log(payload)
            state.gameContestParticipations.splice(0);
            state.gameContestParticipations = payload['hydra:member'];
            state.gameContestParticipationsServerCount = payload['hydra:totalItems'];
        },
        UPDATE_ITEM(state, payload) {
            Object.assign(state.items[payload.index], payload.item);
        },
        ADD_ITEM(state, payload) {
            state.items.push(payload.item);
            state.editedItem = payload.item;
            state.editedIndex = state.items.length - 1;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_GAME_CONTEST_PICTURE(state, payload) {
            if (state.editedItem.image) {
                Object.assign(state.editedItem.image, payload);
            } else {
                state.editedItem.image = payload;
            }
        },
    },
    actions: {
        async getItems({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let pagination = payload.pagination;
                commit('SET_LOADING', true);
                ApiService.getItems(entity, pagination).then(response => {
                    let payload = {
                        items: response.data['hydra:member'],
                        totalItems: response.data['hydra:totalItems']
                    }
                    commit('SET_ITEMS', payload)
                    return resolve();
                }).catch(error => {
                    console.error(error);
                    dispatch("message/showErrorMessage", "Erreur de chargement des jeux concours", {root: true})
                    reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async updateItem({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let index = state.editedIndex;
                let item = {...state.editedItem};
                ApiService.updateItem(item).then(() => {
                    commit('UPDATE_ITEM', {index: index, item: item});
                    dispatch("message/showSuccessMessage", "Jeu concours mise à jour avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de modification de la Jeu concours", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            });
        },
        async deleteItem({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let item = {...state.editedItem};
                ApiService.deleteItem(item).then(() => {
                    dispatch("message/showSuccessMessage", "Jeu concours  effacée avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de modification du Jeu concours", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });

            })
        },
        async createItem({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let item = {...state.editedItem};
                ApiService.createItem(entity, item).then((response) => {
                    commit('ADD_ITEM', {item: response.data});
                    dispatch("message/showSuccessMessage", "Jeu concours ajoutée avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur d'ajout du jeu concours", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async uploadGameContestPicture({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
                let url = '/game_contest_images';
                if (payload['@id']) {
                    url = payload['@id'];
                }
                ApiService.post(url, payload.fd).then((response) => {
                    dispatch("message/showSuccessMessage", "Image envoyé avec succès!", {root: true})
                    commit('SET_GAME_CONTEST_PICTURE', response.data);
                    return resolve(response.data);
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur d'envoie de l'image", {root: true})
                    return reject(error);
                })
            })
        },
        async loadParticipants({dispatch, commit, state}, pagination) {
            return new Promise((resolve, reject) => {
                if (state.gameContestWinnerSelected != null) {
                    pagination.filters = '&gameContest.id=' + state.gameContestWinnerSelected.id;
                    ApiService.getItems('game_contest_participations', pagination).then(response => {
                        commit('SET_PARTICIPATIONS_ITEMS', response.data);
                        return resolve(response.data);

                    }).catch(error => {
                        dispatch("message/showErrorMessage", "Erreur de chargement des participants", {root: true})
                        return reject(error);
                    })
                }
            })
        },
        async luckyDraw({dispatch,state}) {
            return new Promise((resolve, reject) => {
                if (state.gameContestWinnerSelected != null) {
                    ApiService.put('/'+entity + '/' + state.gameContestWinnerSelected.id + '/lucky_draw', state.gameContestWinnerSelected).then(response => {
                        dispatch("message/showSuccessMessage", "Tirage effectué avec succès!", {root: true})
                        return resolve(response);
                    })
                } else {
                    return reject();
                }
            })
        }
    }
}