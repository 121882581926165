<template>
  <v-card dark>
    <v-card-title>
      <game-header header="Gestion des jeux communautaires"/>
      <game-publisher/>
      <game-stages-editor/>
    </v-card-title>
    <v-card-title class="blue">
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
        :headers="headers"
        :items="games"
        :search="search"
        :loading="loading"
        sort-by="id"
        class="elevation-15"
        sort-desc
        :server-items-length="totalGames"
        :page="page"
        :items-per-page="itemsPerPage"
        @update:page="updatePage"
        @update:items-per-page="updateItemsPerPage"
    >
      <!-- template des headers !-->
      <template v-slot:top>
      </template>
      <!-- template des descriptions courtes !-->
      <template v-slot:item.descriptionShort="{ item }">
        <span style="font-size: 0.7em;" v-html="item.descriptionShort"></span>
      </template>
      <!-- template des game devices !-->
      <template v-slot:item.gameDevices="{ item }">
        <v-checkbox :value="item.gameDevices.length > 0" disabled></v-checkbox>
      </template>
      <!-- template des validations !-->
      <template v-slot:item.validatedBy="{ item }">
        <v-checkbox :value="item.validatedBy" disabled :label="item.validatedBy?item.validatedBy.username:''"></v-checkbox>
      </template>
      <template v-slot:item.refusedBy="{ item }">
        <v-checkbox :value="item.refusedBy" disabled :label="item.refusedBy?item.refusedBy.username:''"></v-checkbox>
      </template>
      <!-- template des categoryElements !-->
      <template v-slot:item.categoryElements="{ item }">
        <v-row>
          <pg-icon
              v-for="(element,e) in item.categoryElements"
              v-bind:key="e"
              s-m
          >{{ element['icon'] }}
          </pg-icon>
        </v-row>
      </template>
      <!-- template des gameType !-->
      <template v-slot:item.gameType="{ item }">
        {{ getGameTypeLabel(item) }}
      </template>
      <!-- template des dates de publication !-->
      <template v-slot:item.publicationStart="{ item }">
        {{ getDate(item.publicationStart) }}
      </template>
      <template v-slot:item.publicationEnd="{ item }">
        {{ getDate(item.publicationEnd) }}
      </template>
      <!-- template des boutons de gestion crud !-->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)" color="red" :disabled="item.playedCounter>0">mdi-delete
        </v-icon>
        <v-icon small class="mr-2" @click="publishItem(item)" color="cyan">mdi-publish</v-icon>
        <v-icon small @click="editLevelItem(item)" color="green">mdi-gamepad-square</v-icon>
      </template>
      <!-- template pas de données!-->
      <template v-slot:no-data>
        Aucun jeu ne correspond à vos filtres.
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PgIcon from "../../PGIcon";
import GameMixin from "../../../mixins/GameMixin";
import moment from "moment";
import GameHeader from "../../content/games/GameHeader";
import GamePublisher from "../../content/games/GamePublisher";
import GameStagesEditor from "../../content/game_stages/GameStagesEditor";


export default {
  name: "GamesDataTableCommunity",
  mixins: [GameMixin],
  components: {GameStagesEditor, GamePublisher, GameHeader, PgIcon},
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'id',
          align: 'start',
          filterable: false,
          value: 'id',
        },
        {
          text: 'Titre',
          align: 'start',
          filterable: true,
          value: 'title',
        },
        {text: 'Description courte', value: 'descriptionShort', sortable: false},
        {text: 'Catégories', value: 'categoryElements'},
        {text: 'Type de jeux', value: 'gameType'},
        {text: 'Nombre de parties', value: 'playedCounter'},
        {text: 'Gain pimentos', value: 'pointsReward'},
        {text: 'Coût pimentos', value: 'pointsCost'},
        {text: 'Matériel', value: 'gameDevices'},
        {text: 'Auteur', value: 'author'},
        {text: 'Validé', value: 'validatedBy'},
        {text: 'Refusé', value: 'refusedBy'},
        {text: 'Date début publication', value: 'publicationStart'},
        {text: 'Date fin publication', value: 'publicationEnd'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    getDate(dateFromDb) {
      return dateFromDb ? moment(dateFromDb).format("D-M-Y") : "NC";
    },
    getGameTypeLabel(game) {
      let gameTypeObj = this.gameTypes.find(gameType => {
        return gameType['@id'] === game.gameType;
      });
      return gameTypeObj ? gameTypeObj.name : 'NC'
    },
    editItem(item) {
      this.dialog = true;
      this.$nextTick(() => {
        this.editedIndex = this.games.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.$nextTick(() => {
        this.editedIndex = this.games.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
    publishItem(item) {
      this.dialogPublisher = true;
      this.$nextTick(() => {
        this.editedIndex = this.games.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
    editLevelItem(item) {
      this.dialogGameStageEditor = true;
      this.$nextTick(() => {
        this.editedIndex = this.games.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    }
  },
  created() {
    this.isCommunity=true;
    this.getGameTypes().then(() => {
      if (!this.gameTypes) {
        this.gameType = this.gameTypes[0].code;
      }
    });
    this.getGameFilters();
    this.initialize();
  },
  watch: {
    selectedGameType: function () {
      this.isCommunity=true;
      this.initialize();
    },
    selectedFilters: function () {
      this.isCommunity=true;
      this.initialize();
    },
    filterIsValidatedCommunity : function () {
      this.initialize();
    },
    filterIsRefusedCommunity : function () {
      this.initialize();
    },
    filterIsWaiting : function () {
      this.initialize();
    }
  }
}
</script>

<style scoped>

</style>