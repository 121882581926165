<template>
  <v-container fluid>
    <v-row>
      <v-spacer/>
      <v-col cols="6" lg="3" class="text-right">
      </v-col>
    </v-row>
    <product-data-table/>
  </v-container>
</template>

<script>
import ProductDataTable from "../../components/shop/product/ProductsDataTable";
export default {
  name: "Products",
  components: {ProductDataTable},
}
</script>

<style scoped>

</style>