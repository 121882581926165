import APIService from "../api.service";

class AuthService {
    async login(player) {
        return APIService
            .post('/authentication_token', {
                email: player.email,
                password: player.password
            })
            .then(response => {
                const  roles = response.data.user.roles;
                if (!roles.length && !roles.find(role => role === 'ROLE_ADMIN')) {
                    return Promise.reject('Not an admin user');
                }
                if (response.data.token) {
                    localStorage.setItem('token', response.data.token);
                }
                if(response.data.shop && response.data.shop.token){
                    localStorage.setItem('tokenShop', response.data.shop.token);
                }
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    async logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenShop');
    }

    async register(player) {
        return APIService.post('/shop/client/register', player);
    }

    async verification(token) {
        return APIService.get('/shop/client/verify-account/' + token);
    }
}

export default new AuthService();