var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"dark":""}},[_c('v-card-title',[_vm._v(" Catégories de produit "),_c('v-divider'),_c('product-category-form')],1),_c('v-data-table',{staticClass:"elevation-15",attrs:{"headers":_vm.headers,"items":_vm.productCategories,"search":_vm.search,"loading":_vm.loading,"server-items-length":_vm.totalProductCategories,"sort-by":"id","sort-desc":"","page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"0.7em"},domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.updatedAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"no-data",fn:function(){return [_vm._v(" Aucune catégorie de produit trouvée. ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }