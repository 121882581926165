<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="1000px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark
               class="mb-2"
               v-bind="attrs"
               v-on="on">
          <v-icon>mdi-plus-circle</v-icon>
          Ajouter un jeu hebdo
        </v-btn>
      </template>
      <v-card dark v-if="dialog">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                v-if="dialog"
            >
              <v-row justify="center" align="center">
                <v-col cols="12" class="pa-0">
                  <v-text-field
                      v-model="editedItem.title"
                      label="Titre"
                      :rules="nameRules"
                      :counter="255"
                      required
                  />
                </v-col>
                <v-col cols="12" class="pa-0">
                  <label class="label">Description</label>
                  <quill-editor class="pg-color-deep-red" v-model="editedItem.description"
                                :options="editorOptions"/>
                </v-col>
                <v-col cols="12">
                  <ImageUploader v-model="editedItem.image" :upload-function="uploadGameContestPicture"
                                 :image-owner="{type:'game_contest','id':editedItem.id}" v-if="editedIndex>-1"
                                 v-on:update:image="saveItem(false)"/>
                </v-col>
                <v-select :items="gameContestTypes"
                          item-text="text"
                          item-value="value"
                          v-model="editedItem.gameContestType"
                          dark
                          chips
                          label="Type de jeu"
                />
                <v-col cols="12" class="pa-0">
                  <v-text-field
                      v-model="editedItem.affiliationUrl"
                      label="Lien partenaire"
                      :counter="255"
                  />
                </v-col>
                <v-row no-gutters align-content="center" align="center" justify="center" class="blue-grey">
                  <v-col cols="6" align-self="center" class="text-center">
                    Date de début de publication : {{ dateStart ? dateStart : 'NC' }}
                    <v-date-picker
                        v-model="dateStart"
                        locale="fr-fr"
                    />
                    <v-btn @click="dateStart=null">Effacer</v-btn>
                  </v-col>
                  <v-col cols="6" align-self="center" class="text-center transition-fast-in-fast-out"
                         v-show="dateStart">
                    Date de fin de publication : {{ dateEnd ? dateEnd : 'NC' }}
                    <v-date-picker
                        v-model="dateEnd"
                        locale="fr-fr"
                        :min="dateStart"

                    />
                    <v-btn @click="dateEnd=null">Effacer</v-btn>
                  </v-col>
                </v-row>

              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveItem"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card dark>
        <v-card-title class="headline">Voulez vous vraiement supprimer ce jeu hebdo ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MessageMixin from "../../../mixins/MessageMixin";
import GameContestMixin from "../../../mixins/content/GameContestMixin";
import ImageUploader from "../../ImageUploader";
import QuillEditorMixin from "../../../mixins/QuillEditorMixin";
import moment from "moment";

export default {
  name: "GameContestForm",
  components: {ImageUploader},
  mixins: [GameContestMixin, MessageMixin, QuillEditorMixin],
  data() {
    return {
      valid: true,
      dateStart: null,
      dateEnd: null,
      nameRules: [
        v => !!v || 'Le titre est obligatoire',
        v => (v && v.length >= 5) || 'Le titre doit faire 5 lettres ou plus',
      ],
      descriptionRules: [
        v => !!v || 'La description courte est obligatoire',
        v => (v && v.length >= 20) || 'La description courte doit faire 20 lettres ou plus',
      ],
      pimentosRules: [
        v => !!v || 'Pimentos obligatoire pour cette catégorie',
        v => (v ? Number.isInteger(Number.parseInt(v)) : true) || 'Seulement des valeurs entières',
        v => (v >= 0) || 'Pas de valeur négative en pimentos'
      ],
    }
  },
  methods: {
    updateDates() {
      if (this.dialogPublisher) {
        this.dateStart = this.editedItem.startDate ? moment(this.editedItem.startDate).format('YYYY-MM-DD') : "";
        this.dateEnd = this.editedItem.endDate ? moment(this.editedItem.endDate).format('YYYY-MM-DD') : "";
      }
    },
    async deleteItemConfirm() {
      if (this.editedIndex > -1) {
        await this.delete().then(() => {
          this.items.splice(this.editedIndex, 1);
        })
      }
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async saveItem(closeOnSave = true) {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          await this.update().then(() => {
            if (closeOnSave) {
              this.close();
            }
            this.$forceUpdate();
          }).catch(() => {
            console.log('hum ou pas')
          });
        } else {
          this.create().then(() => {
            if (closeOnSave) {
              this.close();
            }
            this.$forceUpdate();
          })
        }
      } else {
        this.showErrorMessage("Le formulaire n'est pas valide !");
      }
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouveau jeu hebdo' : 'Editer jeu hebdo'
    },
  },
  created() {
    this.updateDates();
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    editedItem: function () {
      this.updateDates();
    },
    dateStart: function () {
      this.editedItem.startDate = this.dateStart;
    }
    ,
    dateEnd: function () {
      this.editedItem.endDate = this.dateEnd;
    }
  },
}
</script>

<style scoped>

</style>