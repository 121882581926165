import {getField, updateField} from "vuex-map-fields";

export const mercure = {
    namespaced: true,
    state: {
        started: false,
        jwtToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOlsiIl0sInN1YnNjcmliZSI6WyJodHRwczovL2FwaS5wcml2YXRlLWdhbWVzLmNvbS9wbGF5ZXJfbm90aWZpY2F0aW9ucy9wbGF5ZXIvMTEiXX19.5LgPeqEF75YoRlcf3kEIiy1EpBhmkBFchkHA9Lq7lVI',
        lastMessage: null,
        lastEventId:null
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_JWT_TOKEN(state, payload) {
            console.log(payload);
            state.jwtToken = payload;
        },
    }
}