import {createHelpers} from 'vuex-map-fields';

const {mapFields} = createHelpers({
    getterType: 'gameStages/getField',
    mutationType: 'gameStages/updateField'
});
import {mapActions} from "vuex";

export default {
    computed: {
        ...mapFields(['stages', 'dialogAction', 'dialogPlayerInteractionFrom', 'stageDefault', 'editedGameStageAction', 'stagesToBeSaved',
            'editedPlayerInteractionFrom','gameStagePlayerInteractionTypes']),
        hasStageToBeSaved() {
            return this.stagesToBeSaved.length > 0;
        },
        onlySavedStages: function () {
            return this.stages.filter(stage => stage['@id'] !== undefined)
        }
    },
    methods: {
        ...mapActions({
            getGameStages: "gameStages/getGameStages",
            addGameStage: "gameStages/addGameStage",
            deleteGameStage: "gameStages/deleteGameStage",
            saveGameStage: "gameStages/saveGameStage",
            deleteGameStageAction: "gameStages/deleteGameStageAction",
            deletePlayerInteractionFrom: "gameStages/deletePlayerInteractionFrom",
            getGameStagePlayerInteractionTypes: "gameStages/getGameStagePlayerInteractionTypes",
        }),
        setStageToBeSaved(stage) {
            if (this.stagesToBeSaved.indexOf(stage.stageNumber) < 0) {
                this.stagesToBeSaved.push(stage.stageNumber);
            }
        }
    },
}