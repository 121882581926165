import {mapActions, mapGetters} from "vuex";


export default {
    computed: {
        isAuthRoute() {
            return this.$route.name == 'Login';
        },
        ...mapGetters('auth', {
            loggedIn: 'loggedIn',
        }),
    },
    methods: {
        ...mapActions('auth', {
            logoutStore: 'logout'
        }),
        logout() {
            this.logoutStore();
        },
        checkNeedToLogin() {
            if (!this.loggedIn && !this.isAuthRoute && this.$route.name != 'Login') {
                this.$router.push({name: 'Login'}).catch(err => {
                    console.log('Avoid duplicate route :' + err)
                });
            }
        }
    },
    created() {
        this.checkNeedToLogin();
    },
    watch: {
        loggedIn: function () {
            this.checkNeedToLogin();
        }
    }
}