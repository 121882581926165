import {getField, updateField} from 'vuex-map-fields';
import ShopService from "../../../services/shop.service";

function cleanProductForAPI(product) {
    if (product.awardPoints === "") {
        product.awardPoints = null;
    } else {
        product.awardPoints = parseInt(product.awardPoints);
    }
    if (product.gameCreditsCoins === "") {
        product.gameCreditsCoins = null;
    } else {
        product.gameCreditsCoins = parseInt(product.gameCreditsCoins);
    }
    if (product.awardPointsCost === "") {
        product.awardPointsCost = null;
    } else {
        product.awardPointsCost = parseInt(product.awardPointsCost);
    }
    if (product.category && typeof product.category !== 'string') {
        product.category = product.category['@id'];
    }
    return product;
}

export const product = {
    namespaced: true,
    state: {
        products: [],
        totalProducts: 0,
        loading: false,
        dialog: false,
        dialogDelete: false,
        editedItem: {
            name: "",
            description: "",
            price: null,
            category: null,
            awardPoints: null,
            gameCreditsCoins: null,
            awardPointsCost: null
        },
        editedIndex: -1,
        defaultItem: {
            name: "",
            description: "",
            price: null,
            category: null,
            awardPoints: null,
            gameCreditsCoins: null,
            awardPointsCost: null
        },
        page: 1,
        itemsPerPage: 15,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_PRODUCT(state, payload) {
            state.products = payload.products;
            state.totalProducts = payload.totalProducts;
        },
        UPDATE_PRODUCT(state, payload) {
            Object.assign(state.products[payload.index], payload.product);
        },
        ADD_PRODUCT(state, payload) {
            state.products.push(payload.product);
            state.editedItem = payload.product;
            state.editedIndex = state.products.length - 1;
        },
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
    },
    actions: {
        async getProducts({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let pagination = payload.pagination;
                commit('SET_LOADING', true);
                ShopService.getProducts(pagination).then(response => {
                    let payload = {
                        products: response.data['hydra:member'],
                        totalProducts: response.data['hydra:totalItems']
                    }
                    commit('SET_PRODUCT', payload)
                    return resolve();
                }).catch(error => {
                    console.error(error);
                    dispatch("message/showErrorMessage", "Erreur de chargement des catégories de produit", {root: true})
                    reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async updateProduct({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let index = state.editedIndex;
                let product = {...state.editedItem};
                product = cleanProductForAPI(product);
                ShopService.updateProduct(product).then(() => {
                    commit('UPDATE_PRODUCT', {index: index, product: product});
                    dispatch("message/showSuccessMessage", "Produit mise à jour avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de modification de la produit", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            });
        },
        async deleteProduct({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let product = {...state.editedItem};
                ShopService.deleteProduct(product).then(() => {
                    dispatch("message/showSuccessMessage", "Produit  effacée avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de modification de la catégorie", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });

            })
        },
        async createProduct({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let product = {...state.editedItem};
                product = cleanProductForAPI(product);
                ShopService.createProduct(product).then((response) => {
                    commit('ADD_PRODUCT', {product: response.data});
                    dispatch("message/showSuccessMessage", "Produit ajoutée avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur d'ajout de la produit", {root: true})
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
    }
}