<template>
  <v-container fluid>
    <v-row>
      <v-spacer/>
      <v-col cols="6" lg="3" class="text-right">
      </v-col>
    </v-row>
    <players-data-table/>
  </v-container>
</template>

<script>
import PlayersDataTable from "../../components/players/manager/PlayersDataTable";

export default {
  name: "PlayersManager",
  components: {PlayersDataTable}
}
</script>

<style scoped>

</style>