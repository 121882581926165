<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="1000px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark
               class="mb-2"
               v-bind="attrs"
               v-on="on">
          <v-icon>mdi-plus-circle</v-icon>
          Ajouter un produit
        </v-btn>
      </template>
      <v-card dark v-if="dialog">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                v-if="dialog"
            >
              <v-row justify="center" align="center">
                <v-col cols="12" class="pa-0">
                  <v-text-field
                      v-model="editedItem.name"
                      label="Titre"
                      :rules="nameRules"
                      :counter="255"
                      required
                  />
                </v-col>

                <v-col cols="12" class="pa-0">
                  <label class="label">Description courte</label>
                  <quill-editor class="pg-color-deep-red" v-model="editedItem.description"
                                :options="editorOptions"/>

                </v-col>
                <v-col cols="10">
                  <product-categories-select v-model="editedItem.category" :item-value="'@id'"/>
                </v-col>
                <v-col cols="2" class="pa-0">
                  <v-currency-field v-model="editedItem.price"/>
                </v-col>
                <v-col cols="1">
                  <pg-icon>i-pimentos</pg-icon>
                </v-col>
                <v-col cols="11">
                  <v-text-field type="number" label="Cout en pimentos" v-model="editedItem.awardPointsCost"
                                step="1"
                                :rules="pimentosRules"
                  />
                </v-col>
                <v-col cols="1">
                  <pg-icon>i-pimentos</pg-icon>
                </v-col>
                <v-col cols="11">
                  <v-text-field type="number" label="Gain en pimentos" v-model="editedItem.awardPoints"
                                step="1"
                                :rules="pimentosRules"
                                :required="editedItem.category && editedItem.category.isAwardPointsProduct"
                  />
                </v-col>
                <v-col cols="1">
                  <pg-icon>i-game-credits-coin</pg-icon>
                </v-col>
                <v-col cols="11">
                  <v-text-field type="number" label="Partie(s) supplémentaire" v-model="editedItem.gameCreditsCoins"
                                step="1"
                                :rules="creditCoinsRules"
                                :required="editedItem.category && editedItem.category.isGameCreditsCoinsProduct"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveProduct"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card dark>
        <v-card-title class="headline">Voulez vous vraiement supprimer ce produit ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MessageMixin from "../../../mixins/MessageMixin";
import QuillEditorMixin from "../../../mixins/QuillEditorMixin";
import ProductMixin from "../../../mixins/shop/ProductMixin";
import ProductCategoriesSelect from "../product_categories/ProductCategoriesSelect";
import VCurrencyField from "../../VCurrencyField";
import PgIcon from "../../PGIcon";

export default {
  name: "ProductForm",
  components: {PgIcon, VCurrencyField, ProductCategoriesSelect},
  mixins: [ProductMixin, MessageMixin, QuillEditorMixin],
  data() {
    return {
      valid: true,
      nameRules: [
        v => !!v || 'Le titre est obligatoire',
        v => (v && v.length >= 5) || 'Le titre doit faire 5 lettres ou plus',
      ],
      descriptionRules: [
        v => !!v || 'La description courte est obligatoire',
        v => (v && v.length >= 20) || 'La description courte doit faire 20 lettres ou plus',
      ],
      pimentosRules: [
        v => (v ? Number.isInteger(Number.parseInt(v)) : true) || 'Seulement des valeurs entières',
        v => ((v != undefined || v != null) ? v >= 0 : true) || 'Pas de valeur négative en pimentos'
      ],
      creditCoinsRules: [
        v => ((v != undefined || v != null) ? Number.isInteger(Number.parseInt(v)) : true) || 'Seulement des valeurs entières',
        v => ((v != undefined || v != null) ? v >= 0 : true) || 'Pas de valeur négative en partie(s) supplémentaire(s)'
      ]
    }
  },
  methods: {
    async deleteItemConfirm() {
      if (this.editedIndex > -1) {
        await this.deleteProduct().then(() => {
          this.products.splice(this.editedIndex, 1);
        })
      }
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async saveProduct(closeOnSave = true) {
      this.$refs.form.validate();
      if (!this.editedItem.category) {
        this.valid = false;
      }
      if (this.valid) {
        if (this.editedIndex > -1) {
          await this.updateProduct().then(() => {
            if (closeOnSave) {
              this.close();
            }
            this.$forceUpdate();
          }).catch(() => {
            console.log('hum ou pas')
          });
        } else {
          this.createProduct().then(() => {
            if (closeOnSave) {
              this.close();
            }
            this.$forceUpdate();
          })
        }
      } else {
        this.showErrorMessage("Le formulaire n'est pas valide !");
      }
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouvelle catégorie' : 'Editer catégorie'
    },
    isAwardPointsRequired() {
      return this.editedItem.category.isAwardPointsProduct ? this.editedItem.category.isAwardPointsProduct : false;
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
}
</script>

<style scoped>

</style>