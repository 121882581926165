import {mapGetters} from "vuex";

export default {
    data: () => ({
        email: "",
        password: '',
        loading: false,
        message: '',
        show1: false,
        showAlert: false,
        messageType: 'error',
        validForm: true,
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
            emailMatch: () => (`The email and password you entered don't match`),
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Invalid e-mail.'
            },
        },
    }),
    computed: {
        ...mapGetters('auth', {
            loggedIn: 'loggedIn',
        }),
        from(){
            return this.$store.state.RouterModule.from;
        }

    },
    created() {
    },
}