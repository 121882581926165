import {createHelpers} from 'vuex-map-fields';

const {mapFields} = createHelpers({
    getterType: 'avatar/getField',
    mutationType: 'avatar/updateField'
});

export default {
    computed: {
        ...mapFields({
            avatarElementCategories: "avatarElementCategories",
            avatarElementEdition: 'avatarElementEdition',
            avatarElement: 'avatarElement',
            avatarGenderTypes: 'avatarGenderTypes',
            avatarShapeTypes: 'avatarShapeTypes',
            avatarHairColors: 'avatarHairColors',
            avatarSkinColors: 'avatarSkinColors',
            loading:'loading'
        }),
    },
    methods:{
        getImagePath(imgUrl) {
            return process.env.VUE_APP_API_URL + imgUrl;
        }
    }
}