import Games from "../views/content/Games";
import Avatars from "../views/content/Avatars";
import GamesContest from "../views/content/GamesContest";
export default {
    path: '/content',
    name: 'Content',
    component: () => import('../layouts/Base'),
    children: [
        {
            path: "/content/games",
            name: "ContentGames",
            component: Games,
        },
        {
            path: "/content/avatars",
            name: "ContentAvatars",
            component: Avatars,
        },
        {
            path: "/content/games-contest",
            name: "ContentGamesContest",
            component: GamesContest,
        },
    ]
};
