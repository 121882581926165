<template>
  <v-row no-gutters>
    <v-col>
      <GameTypesSelect v-model="selectedGameType" item-value="code"/>
    </v-col>
    <v-col v-for="(gameCategory,n) in filteredCategories" v-bind:key="gameCategory.id">
      <v-select dark
                v-model="selectedFilters[n]"
                :items="gameCategory.gameCategoryElements"
                item-value="id"
                deletable-chips
                clearable
                chips
                :label="gameCategory.name"
                item-text="name"
                validate-on-blur
                v-on:blur="updateValue"
                multiple>
        <template v-slot:selection="{ item,disabled }">
          <v-chip class="pg-color-dark-moderate-red" dark :disabled="disabled" close
                  @click:close="removeFilter(n,item.id)">
            <pg-icon class="mr-5">{{ item.icon }}</pg-icon>
            <span v-if="!$vuetify.breakpoint.mobile">{{ item.name }}</span>
          </v-chip>
        </template>
        <template v-slot:item="{item,on, attrs}">
          <v-checkbox v-model="attrs.inputValue"/>
          <pg-icon class="mr-5">{{ item.icon }}</pg-icon>
          {{ item.name }}
        </template>
      </v-select>
    </v-col>
    <v-col v-if="isCommunity">
      <v-checkbox label="Validé" :value="filterIsValidatedCommunity" @click="setValidatedFilter"></v-checkbox>
      <v-checkbox label="Refusé" :value="filterIsRefusedCommunity" @click="setRefusedFilter"></v-checkbox>
      <v-checkbox label="En attente" :value="filterIsWaiting" @click="setWaitingFilter"></v-checkbox>
    </v-col>
    <v-col class="text-center ma-0 pa-0" v-if="selectedFilters.length">
      <v-btn class="pg-color-maroon" dark @click="reset">Reset</v-btn>
    </v-col>
  </v-row>
</template>

<script>

import GameMixin from "../../../mixins/GameMixin";
import PgIcon from "../../PGIcon";
import GameTypesSelect from "../game_types/GameTypesSelect";


export default {
  name: "GameFilter",
  components: {GameTypesSelect, PgIcon},
  mixins: [GameMixin],
  props: {
    value: {
      type: Array
    }
  },
  computed: {
    filteredCategories() {
      return this.gameFilters.filter(filter => filter.gameCategories.length === 0);
    },
  },
  methods: {
    setValidatedFilter() {
      this.filterIsValidatedCommunity = !this.filterIsValidatedCommunity;
      if(this.filterIsValidatedCommunity)
      {
        this.filterIsRefusedCommunity = false;
      }
      if (this.filterIsValidatedCommunity && this.filterIsWaiting) {
        this.filterIsWaiting = false;
      }
    },
    setRefusedFilter() {
      this.filterIsRefusedCommunity = !this.filterIsRefusedCommunity;
      if(this.filterIsRefusedCommunity)
      {
        this.filterIsValidatedCommunity = false;
      }
      if (this.filterIsRefusedCommunity && this.filterIsWaiting) {
        this.filterIsWaiting = false;
      }
    },
    setWaitingFilter() {
      this.filterIsWaiting = !this.filterIsWaiting;
      this.filterIsValidatedCommunity = false;
      this.filterIsRefusedCommunity = false;
    },
    removeFilter(index, id) {
      this.selectedFilters[index].splice(this.selectedFilters[index].indexOf(id), 1);
    },
    updateValue: function () {
      this.$emit('input', this.selectedFilters);
    },
    reset() {
      this.selectedFilters = [];
    }
  },
  created() {
    if (!this.gameFilters.length) {
      this.getGameFilters();
    }
  },
  watch: {
    value: function () {
      this.selectedFilters = this.value;
    }
  }
}
</script>

<style scoped>
.v-menu__content {
  min-width: 80vw !important;
}


</style>