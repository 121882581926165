<template>
    <v-avatar size="28">
      <v-img src="~@/assets/mercure.jpg" :gradient="gradiantMercure"/>
    </v-avatar>
</template>

<script>
import MercureMixin from "../mixins/MercureMixin";

export default {
  name: "MercureStatus",
  mixins:[MercureMixin],
  created() {
    this.setupMercureFake();
  },
  computed:{
    gradiantMercure(){
      return this.mercureStarted?'to top right, rgba(14,164,50,.33), rgba(14,164,50,.7)':'to top right, rgba(164,14,32,.33), rgba(164,14,32,.7)';
    }
  }
}
</script>

<style scoped>
back {
  background-color: rgba(164, 14, 32, 0.99);
}
</style>