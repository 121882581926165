<template>
  <v-toolbar dark shaped height="auto">
    <v-toolbar-title>
      <v-row no-gutters><h3 class="mb-3">{{header}} :</h3>
        <GameFilter/>
      </v-row>
    </v-toolbar-title>
    <v-divider
        class="mx-4"
        inset
        vertical
    ></v-divider>
    <v-spacer/>
    <GameForm/>
  </v-toolbar>
</template>

<script>
import GameForm from "./GameForm";
import GameFilter from "./GameFilter";
export default {
  name: "GameHeader",
  props:{
    header:{
      type: String,
      default:'Gestionnaire des jeux'
    }
  },
  components: {GameFilter, GameForm}
}
</script>

<style scoped>

</style>