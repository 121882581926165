<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="dialog"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar
            dark
        >Mise à jour de l'application
        </v-toolbar>
        <v-card-text>
          <div class="text-h2 pa-12">Une nouvelle version de l'application est disponible, cliquez ici pour mettre à jour !</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="dialog.value = false"
          >Mettre à jour
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "RefreshManager",
  data: function () {
    return {
      dialog: false,
    }
  },
  methods: {
    updateAvailable(event) {
      console.log("On va demander le refresh");
      console.log(event);
      this.dialog = true;
    },
    update(){
      this.dialog=false;
      window.location.reload();
    }
  },
  created() {
    console.log('We listen to swUpdated')
    document.addEventListener('swUpdated', this.updateAvailable, {once: true});
  }
}
</script>

<style scoped>

</style>