import ProductCategories from "../views/shop/ProductCategories";
import Products from "../views/shop/Products";
export default {
    path: '/shop',
    name: 'Shop',
    component: () => import('../layouts/Base'),
    children: [
        {
            path: "/shop/product_categories",
            name: "ProductCategories",
            component: ProductCategories,
        },
        {
            path: "/shop/products",
            name: "Products",
            component: Products,
        },
    ]
};
