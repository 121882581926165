<template>
  <v-slider
      v-model="selected"
      :tick-labels="ticksLabels"
      :min="0"
      :max="3"
      step="1"
      ticks="always"
      :tick-size="6"
      thumb-label="always"
      class="mt-1"
  >
    <template v-slot:thumb-label="">
      <pg-icon>i-calendar</pg-icon>
    </template>
  </v-slider>
</template>

<script>
import PgIcon from "../../PGIcon";
export default {
  name: "GameDurationSlider",
  components: {PgIcon},
  props: ['value'],
  data() {
    return {
      selected: 1,
      ticksLabels: [
        'Rapide',
        'Journée',
        '3 jours',
        'Longue durée',
      ],
      ticksValue:["7200","86400","259200","-1"]
    }
  },
  created() {
    this.selected = this.value;
  },
  watch: {
    selected: function () {
      console.log(this.ticksValue[this.selected])
      this.$emit('input', this.ticksValue[this.selected]);
    },
    value: function () {
      this.selected = this.ticksValue.indexOf(this.value);
    }
  }

}
</script>

<style scoped>

</style>