<template>
  <v-card dark>
    <v-card-title>
      Administrateurs
      <v-divider/>
      <admin-form/>
    </v-card-title>
    <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        :server-items-length="totalProducts"
        sort-by="id"
        sort-desc
        class="elevation-15"
        :page="page"
        :items-per-page="itemsPerPage"
        @update:page="updatePage"
        @update:items-per-page="updateItemsPerPage"
    >
      <!-- template des headers !-->
      <template v-slot:top>
      </template>
      <!-- template des éléments !-->
      <!-- template des boutons de gestion crud !-->
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon small class="mr-2" @click="deleteItem(item)" color="red">mdi-delete</v-icon>
            </template>
      <!-- template pas de données!-->
      <template v-slot:no-data>
        Aucun joueur trouvé.
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";
import AdminForm from "./AdminForm";
import AdminMixin from "../../../mixins/admins/AdminMixin";

export default {
  name: "AdminsDataTable",
  mixins: [AdminMixin],
  components: {AdminForm},
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'id',
          align: 'start',
          filterable: false,
          value: 'id',
        },
        {text: 'Mail', value: 'email', sortable: true},
        {text: 'Nom utilisateur', value: 'username'},
        {text: 'Nom', value: 'lastName'},
        {text: 'Prénom', value: 'firstName'},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  },
  methods: {
    getDate(dateFromDb) {
      return dateFromDb ? moment(dateFromDb).format("D-M-Y") : "NC";
    },
    editItem(item) {
      this.dialog = true;
      this.$nextTick(() => {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.$nextTick(() => {
        this.editedIndex = this.items.indexOf(item);
        this.editedItem = Object.assign({}, item);
      })
    },
  },
  created() {
    this.initialize();
  },
  watch: {}
}
</script>

<style scoped>

</style>