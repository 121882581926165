import {getField, updateField} from 'vuex-map-fields';
import AvatarService from "../../../services/avatar.service";

export const avatar = {
    namespaced: true,
    state: {
        avatarElementCategories: [],
        avatarElement: {
            skinColor: null,
            hairColor: null,
            color: "",
            isPremium: false,
            hasToBeOwned: false,
            genderType: null,
            shapeType: null,
            img: {},
            avatarElementCategory: null,
            parentAvatarElement: null
        },
        avatarElementEmpty: {
            skinColor: null,
            hairColor: null,
            color: "",
            isPremium: false,
            hasToBeOwned: false,
            genderType: null,
            shapeType: null,
            img: {},
            avatarElementCategory: null,
            parentAvatarElement: null
        },
        avatarElementEdition: false,
        avatarGenderTypes: [],
        avatarShapeTypes: [],
        avatarHairColors: [],
        avatarSkinColors: [],
        page: 1,
        itemsPerPage: 5,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
        loading: false
    },
    getters: {
        getField,
    },
    mutations: {
        updateField,
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
        SET_AVATAR_ELEMENT_CATEGORIES(state, payload) {
            state.avatarElementCategories = payload.sort(function (a, b) {
                return a.zIndex - b.zIndex;
            });
        },
        SET_AVATAR_GENDER_TYPES(state, payload) {
            state.avatarGenderTypes = payload;
        },
        SET_AVATAR_SHAPE_TYPES(state, payload) {
            state.avatarShapeTypes = payload;
        },
        SET_AVATAR_HAIR_COLORS(state, payload) {
            state.avatarHairColors = payload;
        },
        SET_AVATAR_SKIN_COLORS(state, payload) {
            state.avatarSkinColors = payload;
        },
        SET_AVATAR_ELEMENT_CATEGORY_PICTURE(state, payload) {
            console.log(payload);
            let index = state.avatarElementCategories.findIndex(category => category['@id'] === payload.avatarCategoryId);
            console.log(index);
            state.avatarElementCategories[index].img = payload.data;
        },
        SET_AVATAR_ELEMENT_PICTURE(state, payload) {
            state.avatarElement.img = payload.data;
        },
        UPDATE_AVATAR_ELEMENT_CATEGORY(state, payload) {
            Object.assign(state.avatarElementCategories[payload.index], payload.avatarElementCategory);
        },
        CLEAR_AVATAR_ELEMENT(state) {
            state.avatarElement = {...state.avatarElementEmpty};
            state.avatarElementEdition = false;
        },
        ADD_AVATAR_ELEMENT(state, payload) {
            let index = state.avatarElementCategories.findIndex(category => category['@id'] === payload.avatarElement.avatarElementCategory);
            state.avatarElementCategories[index].avatarElements.push(payload.avatarElement);
            state.avatarElement = {...payload.avatarElement};
        },
        UPDATE_AVATAR_ELEMENT(state, payload) {
            console.log(payload)
            let index = state.avatarElementCategories.findIndex(category => category['@id'] === payload.avatarElement.avatarElementCategory);
            let indexElement = state.avatarElementCategories[index].avatarElements.findIndex(element => element['@id'] === payload.avatarElement['@id']);
            console.log(payload)
            console.log(state.avatarElementCategories[index])
            console.log(state.avatarElementCategories[index].avatarElements[indexElement])
            Object.assign(state.avatarElementCategories[index].avatarElements[indexElement], payload.avatarElement);
        }
    },
    actions: {
        async getAvatarGenderTypes({commit}) {
            return new Promise((resolve, reject) => {
                AvatarService.getAvatarGenderTypes().then(response => {
                    commit('SET_AVATAR_GENDER_TYPES', response.data['hydra:member']);
                    return resolve();
                }).catch(error => {
                    return reject(error);
                });
            })
        },
        async getAvatarShapeTypes({commit}) {
            return new Promise((resolve, reject) => {
                AvatarService.getAvatarShapeTypes().then(response => {
                    commit('SET_AVATAR_SHAPE_TYPES', response.data['hydra:member']);
                    return resolve();
                }).catch(error => {
                    return reject(error);
                });
            })
        },
        async getAvatarHairColors({commit}) {
            return new Promise((resolve, reject) => {
                AvatarService.getAvatarHairColors().then(response => {
                    commit('SET_AVATAR_HAIR_COLORS', response.data['hydra:member']);
                    return resolve();
                }).catch(error => {
                    return reject(error);
                });
            })
        },
        async getAvatarSkinColors({commit}) {
            return new Promise((resolve, reject) => {
                AvatarService.getAvatarSkinColors().then(response => {
                    commit('SET_AVATAR_SKIN_COLORS', response.data['hydra:member']);
                    return resolve();
                }).catch(error => {
                    return reject(error);
                });
            })
        },
        async getAvatarElementCategories({commit}) {
            commit('SET_LOADING', true);
            return new Promise((resolve, reject) => {
                AvatarService.getAvatarElementCategories().then(response => {
                    commit('SET_AVATAR_ELEMENT_CATEGORIES', response.data['hydra:member']);
                    return resolve();
                }).catch(error => {
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async uploadAvatarPicture({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
                AvatarService.uploadAvatarPicture(payload.fd, payload['@id']).then((response) => {
                    console.log(payload);
                    dispatch("message/showSuccessMessage", "Image envoyé avec succès!", {root: true})
                    if (payload.type === 'avatar_element_categories') {
                        commit('SET_AVATAR_ELEMENT_CATEGORY_PICTURE', {
                            data: response.data,
                            avatarCategoryId: payload.idResource
                        });
                        dispatch('updateAvatarElementCategory', {avatarCategoryId: payload.idResource})
                    }
                    console.log(payload.type)
                    if (payload.type === 'avatar_elements') {
                        commit('SET_AVATAR_ELEMENT_PICTURE', {
                            data: response.data,
                            avatarCategoryId: payload.idResource
                        });
                        dispatch('updateAvatarElement')
                    }
                    return resolve(response.data);
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur d'envoie de l'image", {root: true})
                    return reject(error);
                })
            })
        },
        async updateAvatarElementCategory({commit, dispatch, state}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                if (payload['@id'] === null) {
                    AvatarService.createAvatarElementCategory(payload).then(() => {
                        dispatch("message/showSuccessMessage", "Catégorie d'élement ajoutée avec succès!", {root: true})
                        dispatch("getAvatarElementCategories");
                        return resolve(payload);
                    }).catch(error => {
                        dispatch("message/showErrorMessage", "Erreur d'ajout de la catégorie", {root: true})
                        return reject(error);
                    }).finally(() => {
                        commit('SET_LOADING', false);
                    })
                } else {
                    let index = state.avatarElementCategories.findIndex(category => category['@id'] === payload['@id']);
                    console.log(index)
                    let avatarElementCategory = {...state.avatarElementCategories[index]};
                    console.log(avatarElementCategory)
                    AvatarService.updateAvatarElementCategory(avatarElementCategory).then(() => {
                        commit('UPDATE_AVATAR_ELEMENT_CATEGORY', {
                            index: index,
                            avatarElementCategory: avatarElementCategory
                        });
                        dispatch("message/showSuccessMessage", "Catégorie d'élement mise à jour avec succès!", {root: true})
                        dispatch("getAvatarElementCategories");
                        return resolve();
                    }).catch(error => {
                        dispatch("message/showErrorMessage", "Erreur de modification de la catégorie", {root: true})
                        return reject(error);
                    }).finally(() => {
                        commit('SET_LOADING', false);
                    });
                }
            });
        },
        async clearAvatarElementForm({commit}) {
            return new Promise((resolve) => {
                commit('CLEAR_AVATAR_ELEMENT');
                return resolve(true);
            })
        },
        async addElementToCategory({state}, payload) {
            state.avatarElementEdition = true;
            state.avatarElement.avatarElementCategory = payload;
        },
        async addChildElementToElement({state}, payload) {
            state.avatarElementEdition = true;
            state.avatarElement.avatarElementCategory = payload.avatarElementCategory;
            state.avatarElement.parentAvatarElement = payload;
            state.avatarElement.skinColor = payload.skinColor;
            state.avatarElement.hairColor = payload.hairColor;
            state.avatarElement.genderType = payload.genderType;
            state.avatarElement.shapeType = payload.shapeType;
            state.avatarElement.isPremium = payload.isPremium;
            state.avatarElement.hasToBeOwned = payload.hasToBeOwned;
            state.avatarElement.color = payload.color;
        },
        async clickUpdateAvatarElement({state}, payload) {
            state.avatarElementEdition = true;
            Object.assign(state.avatarElement, payload);
        },
        async loadAvatarElementImage({commit, dispatch}, avatarElement) {
            return new Promise((resolve, reject) => {
                if (!avatarElement.img || avatarElement.img === '') {
                    return reject(false);
                }
                AvatarService.getAvatarPicture(avatarElement.img).then(response => {
                    avatarElement.img = response.data;
                    commit('UPDATE_AVATAR_ELEMENT', {avatarElement: avatarElement});
                    return resolve(avatarElement);
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur chargement d'image d'élément d'avatar", {root: true})
                    return reject(error);
                })
            })
        },
        async createAvatarElement({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                let avatarElement = {...state.avatarElement};
                if (avatarElement.parentAvatarElement != undefined) {
                    avatarElement.parentAvatarElement = avatarElement.parentAvatarElement['@id'];
                }
                delete avatarElement.img;
                AvatarService.createAvatarElement(avatarElement).then((response) => {
                    commit('ADD_AVATAR_ELEMENT', {avatarElement: response.data});
                    dispatch("message/showSuccessMessage", "Élément ajouté avec succès!", {root: true})
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur d'ajout de l'élément", {root: true})
                    return reject(error);
                })
            })
        },
        async updateAvatarElement({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                let avatarElement = Object.assign({}, state.avatarElement);
                AvatarService.updateAvatarElement(avatarElement).then((response) => {
                    avatarElement = Object.assign({}, response.data);
                    commit('UPDATE_AVATAR_ELEMENT', {avatarElement: avatarElement});
                    dispatch("message/showSuccessMessage", "Élément modifié avec succès!", {root: true});
                    return resolve();
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de modification de l'élément", {root: true})
                    return reject(error);
                }).finally(() => {
                    dispatch("loadAvatarElementImage", avatarElement);
                })
            })
        },
        async deleteAvatarElementCategory({commit, dispatch}, payload) {
            if (payload.avatarElements.length >= 0) {
                let ask = window.confirm("Attention, vous allez supprimer tous les avatars de la catégorie ! Certains de ne pas faire une bêtise ?");
                if (!ask) {
                    return;
                }
            }
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                AvatarService.deleteAvatarElementCategory(payload).then((response) => {
                    dispatch("message/showSuccessMessage", "Catégorie supprimé avec succès!", {root: true})
                    dispatch("getAvatarElementCategories");
                    return resolve(response);
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de suppression de la catégorie", {root: true})
                    console.log(error);
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async deleteAvatarElement({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                let avatarElement = {...state.avatarElement};
                AvatarService.deleteAvatarElement(avatarElement).then((response) => {
                    state.avatarElementEdition = false;
                    dispatch("message/showSuccessMessage", "Élément supprimé avec succès!", {root: true})
                    dispatch("getAvatarElementCategories");
                    return resolve(response);
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de suppression de l'élément", {root: true})
                    console.log(error);
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async deleteAvatarHairColor({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                AvatarService.deleteAvatarHairColor(payload).then((response) => {
                    dispatch("message/showSuccessMessage", "Élément supprimé avec succès!", {root: true})
                    dispatch("getAvatarHairColors");
                    return resolve(response);
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de suppression", {root: true})
                    console.log(error);
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async addAvatarHairColor({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                AvatarService.createAvatarHairColor(payload).then((response) => {
                    dispatch("message/showSuccessMessage", "Élément ajouté avec succès!", {root: true})
                    dispatch("getAvatarHairColors");
                    return resolve(response);
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur d'ajout", {root: true})
                    console.log(error);
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async deleteAvatarSkinColor({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                AvatarService.deleteAvatarSkinColor(payload).then((response) => {
                    dispatch("message/showSuccessMessage", "Élément supprimé avec succès!", {root: true})
                    dispatch("getAvatarSkinColors");
                    return resolve(response);
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur de suppression", {root: true})
                    console.log(error);
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        },
        async addAvatarSkinColor({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_LOADING', true);
                AvatarService.createAvatarSkinColor(payload).then((response) => {
                    dispatch("message/showSuccessMessage", "Élément ajouté avec succès!", {root: true})
                    dispatch("getAvatarSkinColors");
                    return resolve(response);
                }).catch(error => {
                    dispatch("message/showErrorMessage", "Erreur d'ajout", {root: true})
                    console.log(error);
                    return reject(error);
                }).finally(() => {
                    commit('SET_LOADING', false);
                });
            })
        }

    }
}