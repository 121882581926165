import Login from "../views/Login";
export default {
    path: '/auth',
    name: 'Authorization',
    component: () => import('../layouts/Auth'),
    redirect: { name: 'Login' },
    children: [
        {
            path: "/auth/login",
            name: "Login",
            component: Login,
        },
    ]
};
