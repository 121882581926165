import {createHelpers} from 'vuex-map-fields';
import {mapActions} from "vuex";
import {GameContestSettings} from "../../utils/constants";

const entity = "gameContest";

const {mapFields} = createHelpers({
    getterType: entity + '/getField',
    mutationType: entity + '/updateField'
});

export default {
    computed: {
        ...mapFields(['dialog', 'loading', 'editedItem', 'defaultItem', 'items',
            'totalItems', 'editedIndex', 'dialogDelete',
            'page', 'itemsPerPage', 'gameContestWinnerSelected', 'gameContestParticipations', 'gameContestParticipationsServerCount']),
        gameContestTypes() {
            return [
                {
                    text: GameContestSettings.GAME_CONTEST_TYPES_LABELS[GameContestSettings.GAME_CONTEST_LUCKY_DRAW],
                    value: GameContestSettings.GAME_CONTEST_LUCKY_DRAW
                },
                {
                    text: GameContestSettings.GAME_CONTEST_TYPES_LABELS[GameContestSettings.GAME_CONTEST_QUIZZ],
                    value: GameContestSettings.GAME_CONTEST_QUIZZ
                },
                {
                    text: GameContestSettings.GAME_CONTEST_TYPES_LABELS[GameContestSettings.GAME_CONTEST_WRITE_A_GAME],
                    value: GameContestSettings.GAME_CONTEST_WRITE_A_GAME
                },
            ]
        }
    },
    methods: {
        ...mapActions({
            gets: entity + "/getItems",
            delete: entity + "/deleteItem",
            create: entity + "/createItem",
            update: entity + "/updateItem",
            uploadGameContestPicture: entity + "/uploadGameContestPicture",
            loadPartipants: entity + "/loadParticipants",
            luckyDraw: entity +"/luckyDraw"

        }),
        updatePage(page) {
            this.page = page;
            this.initialize();
        },
        updateItemsPerPage(itemsPerPage) {
            this.itemsPerPage = itemsPerPage;
            console.log(this.itemsPerPage)
            this.initialize();
        },
        initialize() {
            this.gets({
                pagination: {
                    page: this.page,
                    itemsPerPage: this.itemsPerPage
                }
            });
        },
        getGameContestLabel(gameContestType) {
            return GameContestSettings.GAME_CONTEST_TYPES_LABELS[gameContestType];
        }
    },

}